<template>
  <v-app>
    <Header></Header>
    <v-main>
      <div class="home-cta">
        <div class="animation_from_left">
          <h3 class="btitle aos-init aos-animate">
            Welcome to<br>
            Giftcardstonaira<br>
            Rewards
          </h3>
          <p class="bContent">Your loyalty
            means the world to us</p>
        </div>
      </div>

      <div data-aos="fade-up" data-aos-duration="1000"
        class="details-section aos-init aos-animate animation_from_bottom">
        <p class="desc">At Giftcardstonaira, we believe in giving back to our valued customers.
          That's why we're excited to introduce our Giftcardstonaira Rewards program, designed to make every
          transaction
          with us more rewarding. With five fantastic ways to earn rewards, you'll enjoy an array of benefits just by
          being a loyal customer.</p>
        <h3 class="cta">Discover the ways to<br>earn rewards</h3>
        <div data-aos="fade-up" data-aos-duration="1300" class="rewards aos-init aos-animate">
          <div class="reward-card">
            <h4 class="title">Trading<br>Reward Points</h4>
            <p class="desc">For every gift card transaction you complete with us, you'll earn
              Trading Reward Points. The more you trade, the more points you accumulate, which can be redeemed for
              cash or
              other exciting rewards </p>
          </div>
          <div class="reward-card">
            <h4 class="title">Referral<br>Reward Points</h4>
            <p class="desc">Share the Giftcardstonaira experience with your friends and earn
              Referral Reward Points! When your referred friends make transactions with us, you'll earn points that
              can be
              redeemed for cash or other great benefits FOREVER</p>
          </div>
          <div class="reward-card">
            <h4 class="title">Leaderboard</h4>
            <p class="desc">Compete with fellow Giftcardstonaira users and climb the ranks in our
              weekly and monthly Leaderboards. Reach the top 10 positions and earn bonus points, which can be redeemed
              for
              cash and other amazing prizes.</p>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-duration="1300" class="rewards aos-init aos-animate">
          <div class="reward-card">
            <h4 class="title">Polls</h4>
            <p class="desc">Participate in our free polls and have your voice heard. By taking part
              in our polls, you'll earn additional cash straight into your wallet, which can be redeemed for cash or
              other
              exciting rewards.</p>
          </div>
          <div class="reward-card">
            <h4 class="title">Promo Codes</h4>
            <p class="desc">Keep an eye out for special promo codes that we'll occasionally offer.
              These codes will give you added bonuses on your transactions, helping you get even more value from your
              Giftcardstonaira experience.</p>
          </div>
          <div class="reward-card reward-alt">
            <div class="reward-body">
              <p class="desc">Are you ready to make the most of your Giftcardstonaira experience?
              </p>
              <h4 class="title">Get started with us today<br>and start
                unlocking the amazing benefits that await you. Happy trading!</h4>
            </div><a href="https://app.giftcardstonaira.com/register" target="_blank" class="btn get-started-btn mt-5">
              Get Started </a>
          </div>
        </div>
      </div>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Header from "../components/Header.vue";
import Footer from "../components/Footer.vue";
export default {
  name: "RewardTop",
  components: {
    Header,
    Footer
  },
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
@import '../animation.scss';



// .home-cta {
//   display: flex;
//   flex-direction: column;
//   gap: 2rem;
//   align-items: flex-start;
//   justify-content: flex-start;
//   background: #5352EC;
//   background-blend-mode: color-burn;
//   background-repeat: no-repeat;
//   background-size: cover;
//   text-align: left;
//   padding: 8.286rem 4.25rem;
//   margin-top: 2.143rem;

//   .btitle {
//     color: #fffffe;
//     font-size: 7.5rem;
//     line-height: 7.9rem;
//     font-weight: 900;
//   }

// }

.home-cta {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: flex-start;
  justify-content: flex-start;
  background: #5352EC;
  background-blend-mode: color-burn;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: left;
  padding: 8.286rem 4.25rem;
  margin-top: 2.143rem;

  .btitle {

    color: #fffffe;
    font-size: 7.5rem;
    line-height: 7.9rem;
    font-weight: 900;
  }

  .bContent {
    color: #c2c1f9;
    font-size: 1.2rem;
    line-height: normal;
    font-weight: 400;
  }
}

.details-section {

  padding: 3.667rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;

  .desc {
    color: #333;
    font-size: 1rem;
    margin-top: 1.2rem;
    text-align: left;
    line-height: 1.6rem;
    margin-bottom: 1.0rem;
  }

  .cta {
    color: #040417;
    font-size: 3rem;
    font-weight: 600;
    line-height: 3.513rem;
    text-align: left;
    line-height: normal;
  }

  .rewards {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 2rem 0;
    align-items: center;
    justify-content: space-between;

    .reward-card {
      background-color: #f5f5fe;
      border-radius: 1.25rem;
      width: 31%;
      height: 35rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      padding: 2rem 3rem;
      margin-bottom: 1.25rem;

      .title {
        font-weight: 600;
        font-size: 1.7rem;
        text-align: left;
        color: #040417;
        line-height: 2.1rem;
      }

      .desc {
        margin-top: 1rem;
        font-size: 1rem;
        text-align: left;
        color: #333;
        line-height: 1.6rem;
        display: flex;
        font-weight: 400;
        height: 30%;
      }

      .reward-alt {
        background-color: #5352ec !important;
        background-repeat: no-repeat !important;
        background-size: cover;
        background-position: 1rem 1rem;
      }
    }
  }

}

@media only screen and (max-width: 480px) {

  .home-cta {
    margin-top: 3.5rem;
    padding: 4.75rem 2rem 4.5rem;

    .btitle {
      font-size: 3.5rem;
      line-height: 4.417rem;
    }
  }

  .details-section {
    gap: 1.6rem;
    padding: 1rem 1.6rem 1.5rem;

    .cta {
      font-size: 2rem;
      font-weight: 600;
      line-height: normal;
    }

    .rewards {

      .reward-card {
        width: 100%;
        padding: 1.5rem;
        height: 30rem;
        padding: 2rem;
      }
    }
  }
}
</style>
