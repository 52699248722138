<template>
  <div style="background-color:#5352ec;">
    <div class="bg fixed-top">
      <div v-if="showNavigation1" class="navigation-1">
        <div class="app-title animation_from_left">
          <img class="icon" src="../assets/logo.png" alt="" />
          <div>Migo</div>
        </div>
        <ul>
          <li><router-link :to="{ name: 'Home' }"><span
                :class="{ 'active': this.$route.path === '/home' }">Home</span></router-link></li>
          <li><router-link :to="{ name: 'Rate' }"><span
                :class="{ 'active': this.$route.path === '/rate' }">Rate</span></router-link></li>
          <li><router-link :to="{ name: 'Rewards' }"><span
                :class="{ 'active': this.$route.path === '/rewards' }">Rewards</span></router-link></li>
          <li><router-link :to="{ name: 'Download' }"><span
                :class="{ 'active': this.$route.path === '/download' }">Download</span></router-link></li>
        </ul>
        <div class="right-btn animation_from_right">
          <a class="btn login-btn" @click.prevent="clickLogin">Login</a>
          <a class="btn get-started-btn" href="" @click.prevent="clickStarted">Get Started</a>
        </div>
      </div>
      <div v-else class="navigation-2">
        <div class="app-title animation_from_left">
          <img class="icon" src="../assets/logo.png" alt="" />
          <div>Migo</div>
        </div>
        <div class="right-btn animation_from_right">
          <a v-if="!isShow" @click.prevent="clickHidden(true)" href="" style="padding-top: 7px;">
            <img src="../assets/icons/menu.svg" alt="">
          </a>
          <a v-else @click.prevent="clickHidden(false)" href="" style="padding-top: 7px;">
            <img src="../assets/icons/close.svg" alt="">
          </a>
        </div>
      </div>
    </div>

    <div v-if="isShow" class="navigation2">
      <div class="top">
        <div class="app-title">
          <img class="icon" src="../assets/logo.png" alt="" />
          <div>Migo</div>
        </div>
        <div @click="clickHidden(false)" style="padding-top: 8px;">
          <img src="../assets/icons/close.svg" alt="">
        </div>
      </div>
      <div>
        <ul class="navigation">
          <li><router-link :to="{ name: 'Home' }"><span
                :class="{ 'active': this.$route.path === '/home' }">Home</span></router-link></li>
          <li><router-link :to="{ name: 'Rate' }"><span
                :class="{ 'active': this.$route.path === '/rate' }">Rate</span></router-link></li>
          <li><router-link :to="{ name: 'Rewards' }"><span
                :class="{ 'active': this.$route.path === '/rewards' }">Rewards</span></router-link></li>
          <li><router-link :to="{ name: 'Download' }"><span
                :class="{ 'active': this.$route.path === '/download' }">Download</span></router-link></li>
        </ul>
        <div class="center-btn">
          <a class="btn login-btn" href="">Login</a>
          <a class="btn get-started-btn" href="" style="margin-top: 1rem;">Get Started</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Config from '../URL_Config'
export default {
  name: 'HeaderView',
  watch: {
    '$route': {
      handler: function (to, from) {
        console.log('Route changed from', from.path, 'to', to.path);
      },
      deep: true
    }
  },
  data() {
    return {
      isShow: false,
      showNavigation1: true
    }
  },
  mounted() {
    this.checkWidth();
    window.addEventListener('resize', this.checkWidth);
  },
  destroyed() {
    window.removeEventListener('resize', this.checkWidth);
  },
  methods: {
    checkWidth() {
      const showNavigation1 = window.innerWidth > 768;
      if (showNavigation1 != this.showNavigation1) {
        this.showNavigation1 = showNavigation1
      }
    },
    clickHidden() {
      this.isShow = !this.isShow
    },
    clickLogin() {
      window.open(Config.Login, '_blank')
    },
    clickStarted() {
      window.open(Config.Register, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../animation.scss';

.bg {
  width: 100%;
  padding: 1rem 2rem;
  background: rgba(83, 82, 236, 0.9);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);

  .navigation-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    box-sizing: border-box;

    ul {
      display: flex;
      flex-direction: row;
      gap: 4.375rem;

      li {
        font-size: 1rem;
        text-align: center;
        color: #a9a9f6;
        color: #fffffe !important;
        font-weight: 600;

        span {
          color: #a9a9f6;
        }
      }
    }

  }

  .navigation-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    box-sizing: border-box;
  }
}

.navigation2 {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  position: fixed;
  width: 100%;
  min-height: 100%;
  background-color: #5352ec;
  z-index: 100;

  .top {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;

  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4.375rem;
    height: 100%;
    flex-grow: 1;
    margin-top: 6rem;
    padding-left: 0;

    li {
      font-size: 1.5rem;
      font-weight: 400;
      text-align: center;
      color: #a9a9f6;
      color: #fffffe !important;
      font-weight: 600;

      span {
        color: #a9a9f6;
      }
    }
  }

  .center-btn {
    margin-top: 3rem;
    gap: 0.5rem;

    a {
      margin-left: auto;
      margin-right: auto;

    }
  }
}

.active {
  color: #fffffe !important;
  font-weight: 500;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #212529;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.right-btn {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}

.center-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;

  display: flex;
  flex-direction: column;
  justify-content: center;


  gap: 1.5rem;
  width: 100%;

  .a {
    margin: auto;
  }


}

.login-btn {
  padding: 0.875rem 1.5rem;
  border-radius: 6.25rem;
  background: #6c6bef;
  display: flex;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  color: #fffffe;
  font-size: 0.875rem;
}

.get-started-btn {
  padding: 0.875rem 1.5rem;
  border-radius: 6.25rem;
  background: #fffffe;
  display: flex;
  cursor: pointer;
  justify-content: center;
  width: -moz-fit-content !important;
  width: fit-content;
  align-items: center;
  text-decoration: none;
  color: #312ed2;
  font-size: 0.8rem;
  list-style: none !important;
  width: 98.9px;
  height: 41.5px;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.app-title {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    width: 2rem;
    margin-right: 1rem;
  }

  div {
    display: inline-block;
    font-size: 2rem;
    font-weight: 500;
    color: white;
  }
}

</style>
