import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import BigInt from 'json-bigint'

// create an axios instance
const service = axios.create({
  baseURL: 'https://api.migogiftcard.com', // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
})

let _isRefreshToken = false

// request interceptor
service.interceptors.request.use(
  config => {
    config.headers['x-access-token'] = 'eyJhbGciOiJIUzUxMiJ9.eyJsb2dpblR5cGUiOjEsInRpbWVzdGFtcCI6MTcxNjEwODg3MDY3Niwic3ViIjoie1wiaWRcIjoxNzc5ODY1ODc3NDM4OTkyMzg1LFwibG9naW5UeXBlXCI6MSxcInVzZXJuYW1lXCI6XCI3NzIxOTYyOThAcXEuY29tXCIsXCJ1dWlkXCI6XCI0NTNjZTE1NmRmZWU0ODgyOTAzYmYyNjEwODk1YmFlZFwiLFwiaXNBY2Nlc3NUb2tlblwiOnRydWV9IiwiaWF0IjoxNzE2MTA4ODcwfQ.TCOVrBAW-cP9iSDC1lofV92vSPaqlQUtq29C0vgiQzS4SZCrMdnzORzVp05QXhRfP9mh74Xkim1h8efal3sSCg'
    return config
  },
  error => {
    debugger
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data

    const urlStr = response.request.responseURL
    const code = parseInt(res.code)
    res.code = code

    if (code !== 200) {
      return Promise.reject(new Error(res.msg || 'request error'))
    } else {
      return res
    }
  },
  error => {
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

service.defaults.transformResponse = [
  function(data) {
    const json = BigInt({
      storeAsString: true
    })
    return json.parse(data)
  }
]

export default service
