<template>
  <div class="bg">
    <h3 class="item-title ">The only platform that rewards you for trading</h3>
    <div class="item-0">
      <div class="item-bg animation_from_left">
        <img class="top-img" src="../assets/payments.png" alt="">
        <div class="title">{{ data0.title }}</div>
        <div class="content">{{ data0.content }}</div>
        <div class="btn">
          {{ data0.btnTitle }}
        </div>
      </div>
      <div class="item-bg animation_from_right">
        <img class="top-img" src="../assets/reward.png" alt="">
        <div class="title">{{ data1.title }}</div>
        <div class="content">{{ data1.content }}</div>
        <div class="btn">
          {{ data1.btnTitle }}
        </div>
      </div>
    </div>
    <div class="desc-top animation_from_bottom">
      <div class="desc-item-title">
        <h3>Here Is What Makes Us Special</h3>
        <p>
          We know gift card exchange can be a stressful experience which is why we made it our mission to transform the
          process into a
          <span>WAHALA-FREE </span>
          and enjoyable one.
        </p>
      </div>
      <div class="description">
        <div v-for="(item, index) in list" :key="index" class='desc-item'>
          <div class="icon">
            <img :src="item['icon']" alt="">
          </div>
          <div class="desc-title">
            {{ item['title'] }}
          </div>
          <div class="desc-content">
            {{ item['content'] }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import number_six from '@/assets/number_six.svg'
import lock from '@/assets/lock.svg'
import chat_dots from '@/assets/chat_dots.svg'

export default {
  name: 'RewardCard',
  components: {},
  data: () => ({
    data0: {
      title: 'Trade Gift Cards For Cash Instantly',
      content: 'Giftcardstonaira allows you to safely and securely exchange your gift cards at high rates. Our rates are always updated everyday to make sure every gift card holder gets the best value on their transactions.',
      btnTitle: 'GetStarted'
    },
    data1: {
      title: 'Earn Rewards',
      content: 'You can earn extra cash with our trading point system, promo codes, leaderboard, prediction polls and even more from referral bonus.',
      btnTitle: 'Learn more'
    },
    list: [
      {
        title: '6 Years Establishment',
        icon: number_six,
        content: 'We have been in business for 6 years and have had clean records all through. We breathe integrity and excellent customer satisfaction.'
      },
      {
        title: 'Safe & Secure',
        icon: lock,
        content: 'Enjoy a seamless experience with our platform, where safe and secure transactions are at the heart of everything we do, giving you peace of mind.'
      },
      {
        title: 'Excellent Customer Reviews',
        icon: chat_dots,
        content: 'Join our ever-growing family of happy customers! Our platform is renowned for delivering exceptional service and ensuring user satisfaction.'
      }
    ]
  }),
  mounted() {
  },
  methods: {}
}
</script>


<style lang="scss" scoped>
@import '../animation.scss';

.bg {
  padding: 3.667rem 6.667rem 6.667rem 3.667rem;
  display: flex;
  flex-direction: column;
  gap: 6rem;
}

.item-bg {
  flex-grow: 1;
  background: #F5F5FE;
  border-radius: 1.25rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: space-between;
  gap: 1rem;
  padding: 0 3.125rem 3.125rem;
}

.top-img {
  max-height: 25.125rem;
  max-width: 100%;
  object-fit: contain;
}

.title {
  padding-top: 3rem;
  font-size: 2.2rem;
  line-height: 2.7rem;
  text-align: left;
  font-weight: 600;
  max-width: 80%;
  color: #040417;
}

.content {
  color: #333;
  font-size: 1rem;
  max-width: 90%;
  font-weight: 400;
  line-height: 1.7rem;
  margin-top: 0.875rem;
}

.btn {
  padding: 0.75rem 1.5rem;
  border-radius: 6.25rem;
  background: #5352EC;
  display: flex;
  justify-content: center;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  color: #fffffe;
  font-size: .75rem;
}


.top {
  text-align: center;

  h3 {
    color: #040417;
    font-size: 3rem;
    font-weight: 600;
    line-height: 3.513rem;
    text-align: center;
    padding: 0 20%;
    line-height: normal;
  }

  p {
    color: #333;
    font-size: 1rem;
    margin-top: 1.2rem;
    padding: 0 20%;
    text-align: center;
    line-height: 1.6rem;
    font-weight: 400;
  }
}

.description {

  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 3rem 0;

  .desc-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;

    .icon {

      width: 6rem;
      height: 6rem;
      background: #F0F0FD;
      border-radius: 1.25rem;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        display: inline-block;
        vertical-align: middle;
        height: 3.125rem;
        width: auto;
      }
    }

    .desc-title {
      padding-top: 1.5rem;
      padding-bottom: .75rem;
      font-weight: 600;
      font-size: 1.2rem;
      text-align: center;
      color: #040417;
    }

    .desc-content {
      font-size: .9rem;
      text-align: center;
      color: #333;
      line-height: 1.6rem;
      font-weight: 400;
    }
  }
}

.item-0 {
  display: flex;
  gap: 2.5rem;
  padding: 0 2rem;
  justify-content: space-between;
}

.item-title {
  color: #040417;
  font-size: 3rem;
  font-weight: 600;
  line-height: 3.513rem;
  text-align: center;
  padding: 0 20%;
  line-height: normal;
  margin-bottom: .5rem;
}

@media only screen and (max-width: 767px) {
  .item-0 {
    display: flex;
    flex-direction: column;
    align-content: center;
  }

  .item-bg {
    width: 100%;
    margin-bottom: 10px;
  }

  .description {
    display: flex;
    flex-direction: column;
    align-content: center;
    gap: 2rem;
  }
}


.desc-top {
  .desc-item-title {
    text-align: center;
  }
}


@media only screen and (max-width: 480px) {

  .bg {
    gap: 1.6rem;
    padding: 1rem 1.6rem 1.5rem;
  }

  .item-title {
    padding: 0 7%;
    font-size: 2rem;
    font-weight: 600;
    line-height: normal;
  }

  .item-0 {
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 0;
  }

  .item-bg {
    width: 100%;
    margin-bottom: 10px;
  }

  .description {
    display: flex;
    flex-direction: column;
    align-content: center;
    column-gap: 2rem;

    .desc-item {
      width: 100%;
    }

    .desc-content {
      padding: 0 1.2rem;
      font-size: 1rem;
    }
  }

  .desc-top {
    .desc-item-title {
      text-align: center;

      h3 {
        padding: 0 7%;
        font-size: 2rem;
        font-weight: 600;
        line-height: normal;
      }

      p {
        font-size: 1.2rem;
        padding: 0 5%;
      }
    }

  }
}
</style>
