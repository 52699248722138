<template>
  <div class="bg">
    <div class="line"></div>
    <div class="slider-bg">
      <div class="animation">
        <div v-for="(item, index) in dataList" :key="index" class="scroll-item">
          <svg class="icon">
            <use :xlink:href="item"></use>
          </svg>
          <svg class="tp" width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path _ngcontent-rgf-c9=""
              d="M0 12.3137C4.74074 9.99165 8.36403 5.91168 10.0825 0.960404C10.1924 0.661097 11.9794 10.7151 18 12.6538C18 12.6538 10.7148 16.1094 8.98969 22.9526C8.99656 23.0479 8.57732 17.5175 0 12.3137Z"
              fill="#A9A9F6" class="ng-tns-c9-9"></path>
          </svg>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'LogoSlider',
  components: {},
  data: () => ({
    score: 4.5,
    dataList: [
      '#icon-google_play',
      '#icon-nike',
      '#icon-ebay',
      '#icon-sephora',
      '#icon-steam',
      '#icon-nordstom',
      '#icon-amazon',
      '#icon-vanilla',
      '#icon-walmart',
      '#icon-google_play',
      '#icon-nike',
      '#icon-ebay',
      '#icon-sephora',
      '#icon-steam',
      '#icon-nordstom',
      '#icon-amazon',
      '#icon-vanilla',
      '#icon-walmart',
      '#icon-google_play',
      '#icon-nike',
      '#icon-ebay',
      '#icon-sephora',
      '#icon-steam',
      '#icon-nordstom',
      '#icon-amazon',
      '#icon-vanilla',
      '#icon-walmart',
      '#icon-google_play',
      '#icon-nike',
      '#icon-ebay',
      '#icon-sephora',
      '#icon-steam',
      '#icon-nordstom',
      '#icon-amazon',
      '#icon-vanilla',
      '#icon-walmart',
      '#icon-google_play',
      '#icon-nike',
      '#icon-ebay',
      '#icon-sephora',
      '#icon-steam',
      '#icon-nordstom',
      '#icon-amazon',
      '#icon-vanilla',
      '#icon-walmart',
      '#icon-google_play',
      '#icon-nike',
      '#icon-ebay',
      '#icon-sephora',
      '#icon-steam',
      '#icon-nordstom',
      '#icon-amazon',
      '#icon-vanilla',
      '#icon-walmart',
      '#icon-google_play',
      '#icon-nike',
      '#icon-ebay',
      '#icon-sephora',
      '#icon-steam',
      '#icon-nordstom',
      '#icon-amazon',
      '#icon-vanilla',
      '#icon-walmart',
      '#icon-google_play',
      '#icon-nike',
      '#icon-ebay',
      '#icon-sephora',
      '#icon-steam',
      '#icon-nordstom',
      '#icon-amazon',
      '#icon-vanilla',
      '#icon-walmart',
      '#icon-google_play',
      '#icon-nike',
      '#icon-ebay',
      '#icon-sephora',
      '#icon-steam',
      '#icon-nordstom',
      '#icon-amazon',
      '#icon-vanilla',
      '#icon-walmart',
      '#icon-google_play',
      '#icon-nike',
      '#icon-ebay',
      '#icon-sephora',
      '#icon-steam',
      '#icon-nordstom',
      '#icon-amazon',
      '#icon-vanilla',
      '#icon-walmart',
      '#icon-google_play',
      '#icon-nike',
      '#icon-ebay',
      '#icon-sephora',
      '#icon-steam',
      '#icon-nordstom',
      '#icon-amazon',
      '#icon-vanilla',
      '#icon-walmart',
      '#icon-google_play',
      '#icon-nike',
      '#icon-ebay',
      '#icon-sephora',
      '#icon-steam',
      '#icon-nordstom',
      '#icon-amazon',
      '#icon-vanilla',
      '#icon-walmart',
      '#icon-google_play',
      '#icon-nike',
      '#icon-ebay',
      '#icon-sephora',
      '#icon-steam',
      '#icon-nordstom',
      '#icon-amazon',
      '#icon-vanilla',
      '#icon-walmart',
      '#icon-google_play',
      '#icon-nike',
      '#icon-ebay',
      '#icon-sephora',
      '#icon-steam',
      '#icon-nordstom',
      '#icon-amazon',
      '#icon-vanilla',
      '#icon-walmart',
      '#icon-google_play',
      '#icon-nike',
      '#icon-ebay',
      '#icon-sephora',
      '#icon-steam',
      '#icon-nordstom',
      '#icon-amazon',
      '#icon-vanilla',
      '#icon-walmart',
    ],
  }),
  mounted() {
    // this.animation()
  },
  methods: {},
}
</script>


<style lang="scss" scoped>
.bg {
  position: relative;
  height: 8rem;
  overflow: hidden;
  // background-color: blue;

  .line {
    background: #312ed2;
    transform: rotate(2deg);
    transform-origin: top left;
    height: 5rem;
    margin-right: -50px;
    margin-top: 2rem;
    position: absolute;
    width: 150%;

    margin-top: 0;
    height: 5.2rem;
  }
}

.slider-bg {
  background: #4745D7;
  width: 100%;
  padding: 2.5rem 0rem;
  position: absolute;
  display: flex;
  overflow: hidden;
  --gap: 2rem;
  gap: var(--gap);
  -webkit-user-select: none;
  user-select: none;

  min-width: 100%;
  width: 100%;
  gap: 1rem;
  display: flex;
  flex-shrink: 0;
  justify-content: space-around;

  background: #4745d7;
  width: 100%;
  padding: 2.5rem 0rem;
  position: absolute;
  display: flex;
  overflow: hidden;
  --gap: 2rem;
  gap: var(--gap);
  -webkit-user-select: none;
  user-select: none;

  padding: 1.7rem 0rem;
  --gap: 1rem;

  // animation: 20s linear infinite 20s linear infinite;


  // .inner-bg {
  //   width: 5000px;
  //   overflow: hidden;
  //   display: flex;
  //   flex-direction: row;
  //   animation: change 30s linear 0s infinite normal; /*动画元素，持续时间，速度，延迟时间，重复次数，是否往返*/
  .scroll-item {
    display: flex;
    gap: 0rem;
    align-items: center;
    flex-direction: row;

    .icon {
      max-height: 3rem;
      display: block;
      width: auto;
      image-rendering: pixelated;
    }

    .tp {
      height: 2rem;
    }
  }
}

@keyframes change {
  from {
    margin-left: 0;
  }

  to {
    margin-left: -1200px;
  }
}

.animation {

  min-width: 100%;
  gap: 0.5rem;
  display: flex;
  flex-shrink: 0;
  justify-content: space-around;
  animation: change 10s linear 0s infinite normal;
}
</style>
