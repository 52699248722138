<template>
  <v-app>
    <Header></Header>
    <v-main>
      <div class="header-rect">
        <h3>Frequenty Asked Questions</h3>
        <p>Get more information on how our platform works.</p>
      </div>
      <div class="content-rect">
        <el-collapse accordion  v-model="activeNames" >
          <el-collapse-item v-for="(item, index) in  list " :key="index" :title="item.title">
            <div class="collapse">{{ item.content }}</div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>

import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
export default {
  name: 'FapPage',
  components: {
    Footer,
    Header
  },
  data() {
    return {
      list: [
        {
          title: ' What is Giftcardstonaira? ',
          content: 'Giftcardstonaira is a digital retail exchange platform that specializes in the efficient conversion of unused gift cards into cash. Since our inception in 2017, we have been dedicated to offering users in Nigeria and Ghana a secure and straightforward solution for transforming their gaming, shopping, and prepaid gift cards. Our platform ensures quick remittance, allowing individuals to conveniently access the value of their gift cards in their local currency.'
        }, {
          title: ` Hope it's not a scam? `,
          content: `We take great pride in the longevity of our business, which we've built over the years. We hold our reputation in high esteem and would never do anything to tarnish it hastily. Our customers' satisfaction is of utmost importance to us and remains at the forefront of our business practices.`
        }, {
          title: ' What gift cards do you buy? ',
          content: `We buy Steam, Google, Apple iTunes, Sephora, Amazon and many more.`
        }, {
          title: ' Are you always online? ',
          content: `Yes, we maintain 24/7 online presence to provide our customers with the highest level of support and service.`
        }, {
          title: ' Which gift card has the highest rate? ',
          content: `At the moment, Steam, Amex, and Razer gift cards have the highest rates. However, please note that our rates are subject to change based on market trends and demand. We recommend checking our RATE CALCULATOR or contacting our customer service team for the latest rates.`
        }, {
          title: ' How fast will I get paid? ',
          content: `w fast will I get paid? You will typically receive payment within 5-10mins. We strive to process payments as quickly as possible to ensure a seamless experience for our users. Rest assured that we prioritize prompt payment delivery to ensure your funds reach you in a timely manner.`
        }, {
          title: ' How can I get rewarded for trading gift card?',
          content: `There are multiple rewarding opportunities on our platform. The top two methods to earn reward points are trading gift cards, offering points for each transaction, and referring others, granting you points for their transactions. Additionally, our leaderboard system, prediction/poll challenges, and promo codes provide further avenues for rewards. Start earning today!`
        }
      ],
      activeNames: ['1']
    }
  }
}
</script>

<style lang="scss" scoped>
.header-rect {

  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: flex-start;
  justify-content: flex-start;
  background: #5352EC;
  // background-image: url(pattern.3ba50c275ea855d9.svg);
  background-repeat: no-repeat;
  background-size: cover;
  text-align: left;
  margin-top: 4.125rem;
  padding: 8.286rem 4.25rem;

  margin-top: 2.143rem;

  h3 {
    color: #fffffe;
    font-size: 7.5rem;
    line-height: 7.9rem;
    font-weight: 900;
  }

  p {
    color: #c2c1f9;
    font-size: 1.2rem;
    line-height: normal;
    font-weight: 400;
  }
}

.collapse {
  padding: 2rem;
  background: #F5F5FE;
  font-size: 1rem;
  color: #333;
  line-height: 1.9rem;
  font-weight: 400;
}

.content-rect {
  padding: 4.667rem 3.667rem 8.667rem;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  gap: 4rem;


  .el-collapse {
      width: 80%;
    }
}

@media only screen and (max-width: 480px) {
  .header-rect {
    margin-top: 3.5rem;
    padding: 4.75rem 2rem 4.5rem;

    h3 {
      font-size: 3.5rem;
      line-height: 4.417rem;
    }

    p {
      font-size: 1.2rem;
      line-height: normal;
      font-weight: 400;
    }
  }

  .content-rect {
    gap: 1.6rem;
    padding: 1rem 1.6rem 2.5rem;

    .el-collapse {
      width: 100%;
    }
  }
}
</style>
