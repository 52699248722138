<template>
  <v-app>
    <Header></Header>
    <v-main>
      <div>
        <div class="background">
        <h3 class="title-top">
          The reliable gift cards exchanger in Nigeria
        </h3>

        <img src="../assets/about.png" alt="">

        <h4 class="content-1">Giftcardstonaira is a registered gift card trading company established in 2017 dedicated
          to
          providing world-class trading experience to people in Nigeria and Ghana. We buy your gift cards and pay in
          Naira.
          We make instant cash payment after redemption of gift cards.</h4>
        <p>Our mission is to make gift card trading as simple and straightforward as possible, ensuring that you receive
          maximum value for your cards, and providing a hassle-free experience for both buyers and sellers.</p>
        <div>
          <h4>What we do</h4>
          <p>We understand that gift cards can be an incredibly convenient way to show appreciation, celebrate special
            occasions, or even purchase everyday items. However, sometimes these cards can go unused, expire, or simply
            not
            be the right fit for the recipient. That's where our platform comes in - we provide a safe and reliable way
            for
            you to sell your unwanted gift cards and put some cash back in your pocket, while also allowing buyers to
            find
            great deals on gift cards for their favorite stores and brands.<br /><br />
            Our team is dedicated to providing excellent customer service and ensuring that all transactions on our
            platform
            are secure and efficient. We pride ourselves on our transparency, honesty, and integrity, and strive to
            create a
            community of gift card traders who can trust and rely on us for their trading needs.</p>
        </div>
      </div>
      </div>
    </v-main>
    <Footer></Footer>
  </v-app>
</template>

<script>
import Footer from "../components/Footer.vue";
import Header from "../components/Header2.vue";
export default {
  name: "AboutPage",
  components: {
    Footer,
    Header
  },
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
.background {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-items: flex-start;
  justify-content: flex-start;
  background: #FFF;
  text-align: center;
  padding: 8.286rem 8.667rem 1.5rem;

  .title-top {
    color: #040417;
    font-size: 3rem;
    line-height: normal;
    font-weight: 900;
    align-self: center;
    text-align: center;
  }

  .content-1 {
    text-align: left;
    color: #040417;
    font-size: 1rem;
    line-height: normal;
    font-weight: 400;
  }

  h4 {
    font-size: 2rem;
    font-style: italic;
    font-weight: 400;
    line-height: 2.9rem;
    text-align: left;
  }

  .content-2 {
    text-align: left;
    color: #040417;
    font-size: 1rem;
    line-height: normal;
    font-weight: 400;
    margin-top: 1rem !important;

  }

  img {
    width: 100%;
    height: auto;
    max-height: 40rem;
    object-fit: cover;
  }
}

p {
  text-align: left;
}

@media only screen and (max-width: 480px) {
  .background {

  padding: 4.75rem 1.6rem 2.5rem;
  }
}
</style>
