import request from './request'

// 卡列表
export function getCardTypeList(query) {
  return request({
    url: `/biz-gift-card-app/app/giftcard/getCardType`,
    method: 'get',
    query: query
  })
}

// 卡面值
export function getDenominationList(cardTypeId) {
  return request({
    url: `/biz-gift-card-app/app/giftcard/getDenomination`,
    method: 'post',
    data: {cardTypeId}
  })
}

// 卡面值
export function getCurrencyTypeList(cardTypeId) {
  return request({
    url: `/biz-gift-card-app/app/giftcard/getCurrencyType`,
    method: 'get',
    query: {cardTypeId}
  })
}


// 保存用户咨询相关
export function addmemberconult(data) {
  return request({
    url: `/biz-member-app/app/auth/addmemberconult`,
    method: 'post',
    data
  })
}