<template>
  <v-app>
    <Header></Header>
    <v-main>
      <div class="header-rect">
        <h3>Contact Us</h3>
        <p>GiftCardsToNaira is a reliable gift cards exchanger in Nigeria.We buy your gift cards and pay in Naira.
          We make instant cash payment after redemption of gift cards.</p>
      </div>
      <div class="content-rect">
        <div class="content-bg">
          <div class="content-left">
            <div class="content-border">
              <div class="contact-detail">
                <div class="contact-detail-label">Full name</div>
                <ElInput :controls="false" style="width: 100%; text-align: left;" placeholder="Janet Doe"
                  v-model="fullName" />
                <div class="contact-detail-label">Email address</div>
                <ElInput :controls="false" style="width: 100%; text-align: left;" placeholder="janet@email.com"
                  v-model="email" />
                <div class="contact-detail-label">Detail description</div>
                <ElInput type="textarea" :rows=6 :controls="false" style="width: 100%; text-align: left;"
                  v-model="content" placeholder="Enter a detailed description of how we can help you." />
              </div>
              <div class="submit-btn-bg">
                <div class="btn-bg" @click="clickSubmit">
                  <div>Submit</div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-right">
            <div class="contact-bg">
              <div class="item-bg">
                <h4 class="title">Call Us</h4>
                <p class="desc">Call us to speak to a member of our team.
                  We are always happy to help.</p>
                <a class="address">+234 705 837 2518</a>
              </div>
              <div class="contact-bg">
                <div class="item-bg">
                  <h4 class="title">Email Us</h4>
                  <p class="desc">Email us for general queries, including difficulties and any other problem.</p>
                  <a class="address">info@giftcardstonaira.com</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
import { MessageBox, Message } from 'element-ui'
import { addmemberconult } from '../request/information'
export default {
  name: 'ContactPage',
  components: {
    Footer,
    Header
  },
  data() {
    return {
      fullName: '',
      email: '',
      content: ''
    }
  },
  methods: {
    clickSubmit() {
      let { fullName, email, content } = this
      fullName = fullName.trim()
      email = email.trim()
      content = content.trim()

      let infoStr = ''
      if (fullName.length <= 0) {
        infoStr = 'Please input full name'
      } else if (email.length <= 0) {
        infoStr = 'Please input email address'
      } else if (content.length <= 0) {
        infoStr = 'Please input detail description of how to help you '
      }

      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (infoStr.length <= 0 && !regex.test(email)) {
        infoStr = 'Please input correct email address'
      }

      if (infoStr.length > 0) {
        Message({
          message: infoStr,
          type: 'info',
          duration: 5 * 1000
        })
        return
      }

      addmemberconult({ email, fullName, consultContent: content }).then(e => {
        this.fullName = ''
        this.email = ''
        this.content = ''
        Message({
          message: 'Migo will contact you as soon as possible!',
          type: 'success',
          duration: 5 * 1000
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.header-rect {

  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: flex-start;
  justify-content: flex-start;
  background: #FFF;
  text-align: left;
  margin-top: 3rem;
  padding: 4.75rem 3.333rem 2.5rem;

  h3 {
    color: #040417;
    font-size: 2.5rem !important;
    line-height: normal;
    font-weight: 900;
  }

  p {
    color: #040417;
    font-size: 1rem;
    line-height: normal;
    font-weight: 400;
  }
}


.content-rect {
  display: flex;
  flex-direction: column;
  gap: 4rem;

  gap: 3rem;
  padding: 1.333rem 3.333rem 6.333rem;

  .content-bg {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
    margin-right: 0.75rem;
    margin-left: 0.75rem;


    .content-left {
      flex: 0 0 auto;
      width: 58.33333333%;
      padding: 1rem;

      .content-border {
        background: #FFFFFE;
        border: .5rem solid #F5F5FE;
        display: flex;
        justify-content: space-between;
        border-radius: 1.875rem;
        width: 100%;
        padding: 5rem 5rem;
        flex-direction: column;
        gap: 3rem;
      }
    }

    .content-right {
      flex: 0 0 auto;
      width: 41.666666%;
      padding: 1rem;

      .contact-bg {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .item-bg {
          display: flex;
          flex-direction: column;
          gap: .875rem;

          .title {
            font-size: 2rem;
            font-weight: 500;
            color: #040417;
            text-align: left;
          }

          .desc {
            font-size: 1rem;
            color: #333;
            line-height: 1.5rem;
            font-weight: 400;
            margin-bottom: 0;
          }

          .address {
            font-size: 1.2rem;
            color: #5352ec;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.contact-detail {
  padding: 1rem;

  .contact-detail-label {
    margin-bottom: 0.6rem;
    margin-top: 1.5rem;
    margin-left: 1rem;
  }
}

.submit-btn-bg {
  width: 100%;

  .btn-bg {
    margin-top: 3rem;
    margin-bottom: 3rem;
    width: 10rem;
    margin-left: auto;
    margin-right: auto;

    div {
      background: #5352EC;

     

      border-radius: 6.25rem;
      padding: 1rem 2rem;
      border: none;
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 1rem;
      color: #fafafe;
      font-weight: 500;
      align-items: center;
      gap: .625rem;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .content-rect {
    padding: 1.333rem 3.333rem 6.333rem;

    .content-bg {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      margin-right: 0.75rem;
      margin-left: 0.75rem;


      .content-left {
        flex: 0 0 auto;
        width: 100%;
        padding: 1rem;

        .content-border {
          background: #FFFFFE;
          border: .5rem solid #F5F5FE;
          display: flex;
          justify-content: space-between;
          border-radius: 1.875rem;
          width: 100%;
          padding: 5rem 5rem;
          flex-direction: column;
          gap: 3rem;
        }
      }

      .content-right {
        flex: 0 0 auto;
        width: 100%;
        padding: 1rem;

        .contact-bg {
          display: flex;
          flex-direction: column;
          gap: 2rem;

          .item-bg {
            display: flex;
            flex-direction: column;
            gap: .875rem;

            .title {
              font-size: 2rem;
              font-weight: 500;
              color: #040417;
              text-align: left;
            }

            .desc {
              font-size: 1rem;
              color: #333;
              line-height: 1.5rem;
              font-weight: 400;
              margin-bottom: 0;
            }

            .address {
              font-size: 1.2rem;
              color: #5352ec;
              text-decoration: none;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

}

@media only screen and (max-width: 768px) {
  .content-rect {
    padding: 0;

    .content-bg {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      margin-right: 0.75rem;
      margin-left: 0.75rem;


      .content-left {
        flex: 0 0 auto;
        width: 100%;
        // padding: 1rem;

        .content-border {
          background: #FFFFFE;
          border: .5rem solid #F5F5FE;
          display: flex;
          justify-content: space-between;
          border-radius: 1.875rem;
          width: 100%;
          padding: 0rem 0rem;
          flex-direction: column;
          gap: 3rem;
        }
      }

      .content-right {
        flex: 0 0 auto;
        width: 100%;
        padding: 1rem;

        .contact-bg {
          display: flex;
          flex-direction: column;
          gap: 2rem;

          .item-bg {
            display: flex;
            flex-direction: column;
            gap: .875rem;

            .title {
              font-size: 2rem;
              font-weight: 500;
              color: #040417;
              text-align: left;
            }

            .desc {
              font-size: 1rem;
              color: #333;
              line-height: 1.5rem;
              font-weight: 400;
              margin-bottom: 0;
            }

            .address {
              font-size: 1.2rem;
              color: #5352ec;
              text-decoration: none;
              cursor: pointer;
            }
          }
        }
      }
    }
  }

}
</style>
