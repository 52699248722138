<template>
  <div class="bg">
    <div>
      <div class="big-title">Trusted by over 300,000+ customers</div>
      <div>
        <el-rate v-model="score" disabled show-score text-color="#ff0000" score-template="{value}">
        </el-rate>
      </div>
      <div class="download-desc">Score based on thousands of reviews on the Google Playstore and Apple App Store</div>
    </div>
    <div class="slider-bg">
      <ul class="inner-bg">
        <li v-for="(item, index) in dataList" :key="index" class="scroll-item">
          <div class="content-bg">
            <div class="content">{{ item.content }}</div>
            <div class="title-bg">
              <img class="icon" :src="item.icon" alt="" srcset="">
              <div class="name">{{ item.name }}</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import avatar1 from '../assets/avatar1.png'
import avatar2 from '../assets/avatar2.png'
import avatar3 from '../assets/avatar3.png'
import avatar4 from '../assets/avatar4.png'
// import avatar5 from '../assets/avatar5.png'
// import avatar6 from '../assets/avatar6.png'
import avatar7 from '../assets/avatar7.png'
// import avatar8 from '../assets/avatar8.png'
// import avatar9 from '../assets/avatar9.png'
export default {
  name: 'ScrollRect',
  components: {},
  data: () => ({
    score: 4.5,
    dataList: [
      {
        name: 'aaa',
        content:
          'Giftcardstonaira is one of the best gift card trading apps. Please feel free to download. I love its features and everything works smoothly',
        icon: avatar1,
      },
      {
        name: 'aaa',
        content:
          "Honestly, I don't know how to describe how much this app has been of great help to me. Very fast user interface and fast withdrawals...Kudos to the developers.",
        icon: avatar2,
      },
      {
        name: 'aaa',
        content:
          "I really love this app because it is so fast and nice, my junior brother introduced me to this application and it has been long he told about it and I started using it this onth. I love the app because they're so kind ❤😍️ I will continue trading gift card with the app. Thanks so much.",
        icon: avatar3,
      },
      {
        name: 'aaa',
        content:
          'Giftcardstonaira is a wonderful app which i have been using to redeem gift cards going to two years now. They do not delay transactions and they credit you accordingly. Anytime i had issues with my transactions i just chat with their customer care and the issues are immediately resolved. Thanks giftcardstonaira for being reliable. I am one satisfied customer with you',
        icon: avatar4,
      },
      {
        name: 'aaa',
        content:
          "This app i must say is the best app to trade your giftcard. In the past, i was having problems of people cheating me when redeeming my gift card, they will lie that gift card is low and give me low amount but with this app, i am earning a lot. i suggest if you're having problems, better use this app, it's the best ever",
        icon: avatar7,
      },
      {
        name: 'aaa',
        content:
          "Been looking for an exchange app without stressful and mundane procedures for a while now, and i came across giftcardstonaira. At first, i was a bit skeptical but after giving it a shot, i can say it's one of the best and i highly recommend you reading this to give it a try. you won't regret it. ",
        icon: avatar2,
      },
      {
        name: 'aaa',
        content:
          "This app is so amazing, i'm a guy that do doubt a lot and i did also when i was introduced to this app, but after my first trial, my doubt for it disappeared and my love for the app increases in everyday trading i do with it. it's very reliable and fast in terms of crediting, i'm so happy finding this great app. i'm giving it a 5 star because it deserves it, go for Giftcardstonaira, it's the best so far",
        icon: avatar7,
      },
      {
        name: 'aaa',
        content:
          'Giftcardstonaira is one of the best gift card trading apps. Please feel free to download. I love its features and everything works smoothly',
        icon: avatar1,
      },
      {
        name: 'aaa',
        content:
          "Honestly, I don't know how to describe how much this app has been of great help to me. Very fast user interface and fast withdrawals...Kudos to the developers.",
        icon: avatar2,
      },
      {
        name: 'aaa',
        content:
          "I really love this app because it is so fast and nice, my junior brother introduced me to this application and it has been long he told about it and I started using it this onth. I love the app because they're so kind ❤😍️ I will continue trading gift card with the app. Thanks so much.",
        icon: avatar3,
      },
      {
        name: 'aaa',
        content:
          'Giftcardstonaira is a wonderful app which i have been using to redeem gift cards going to two years now. They do not delay transactions and they credit you accordingly. Anytime i had issues with my transactions i just chat with their customer care and the issues are immediately resolved. Thanks giftcardstonaira for being reliable. I am one satisfied customer with you',
        icon: avatar4,
      },
      {
        name: 'aaa',
        content:
          "This app i must say is the best app to trade your giftcard. In the past, i was having problems of people cheating me when redeeming my gift card, they will lie that gift card is low and give me low amount but with this app, i am earning a lot. i suggest if you're having problems, better use this app, it's the best ever",
        icon: avatar7,
      },
      {
        name: 'aaa',
        content:
          "Been looking for an exchange app without stressful and mundane procedures for a while now, and i came across giftcardstonaira. At first, i was a bit skeptical but after giving it a shot, i can say it's one of the best and i highly recommend you reading this to give it a try. you won't regret it. ",
        icon: avatar2,
      },
      {
        name: 'aaa',
        content:
          "This app is so amazing, i'm a guy that do doubt a lot and i did also when i was introduced to this app, but after my first trial, my doubt for it disappeared and my love for the app increases in everyday trading i do with it. it's very reliable and fast in terms of crediting, i'm so happy finding this great app. i'm giving it a 5 star because it deserves it, go for Giftcardstonaira, it's the best so far",
        icon: avatar7,
      },
      {
        name: 'aaa',
        content:
          'Giftcardstonaira is one of the best gift card trading apps. Please feel free to download. I love its features and everything works smoothly',
        icon: avatar1,
      },
      {
        name: 'aaa',
        content:
          "Honestly, I don't know how to describe how much this app has been of great help to me. Very fast user interface and fast withdrawals...Kudos to the developers.",
        icon: avatar2,
      },
      {
        name: 'aaa',
        content:
          "I really love this app because it is so fast and nice, my junior brother introduced me to this application and it has been long he told about it and I started using it this onth. I love the app because they're so kind ❤😍️ I will continue trading gift card with the app. Thanks so much.",
        icon: avatar3,
      },
      {
        name: 'aaa',
        content:
          'Giftcardstonaira is a wonderful app which i have been using to redeem gift cards going to two years now. They do not delay transactions and they credit you accordingly. Anytime i had issues with my transactions i just chat with their customer care and the issues are immediately resolved. Thanks giftcardstonaira for being reliable. I am one satisfied customer with you',
        icon: avatar4,
      },
      {
        name: 'aaa',
        content:
          "This app i must say is the best app to trade your giftcard. In the past, i was having problems of people cheating me when redeeming my gift card, they will lie that gift card is low and give me low amount but with this app, i am earning a lot. i suggest if you're having problems, better use this app, it's the best ever",
        icon: avatar7,
      },
      {
        name: 'aaa',
        content:
          "Been looking for an exchange app without stressful and mundane procedures for a while now, and i came across giftcardstonaira. At first, i was a bit skeptical but after giving it a shot, i can say it's one of the best and i highly recommend you reading this to give it a try. you won't regret it. ",
        icon: avatar2,
      },
      {
        name: 'aaa',
        content:
          "This app is so amazing, i'm a guy that do doubt a lot and i did also when i was introduced to this app, but after my first trial, my doubt for it disappeared and my love for the app increases in everyday trading i do with it. it's very reliable and fast in terms of crediting, i'm so happy finding this great app. i'm giving it a 5 star because it deserves it, go for Giftcardstonaira, it's the best so far",
        icon: avatar7,
      },
      {
        name: 'aaa',
        content:
          'Giftcardstonaira is one of the best gift card trading apps. Please feel free to download. I love its features and everything works smoothly',
        icon: avatar1,
      },
      {
        name: 'aaa',
        content:
          "Honestly, I don't know how to describe how much this app has been of great help to me. Very fast user interface and fast withdrawals...Kudos to the developers.",
        icon: avatar2,
      },
      {
        name: 'aaa',
        content:
          "I really love this app because it is so fast and nice, my junior brother introduced me to this application and it has been long he told about it and I started using it this onth. I love the app because they're so kind ❤😍️ I will continue trading gift card with the app. Thanks so much.",
        icon: avatar3,
      },
      {
        name: 'aaa',
        content:
          'Giftcardstonaira is a wonderful app which i have been using to redeem gift cards going to two years now. They do not delay transactions and they credit you accordingly. Anytime i had issues with my transactions i just chat with their customer care and the issues are immediately resolved. Thanks giftcardstonaira for being reliable. I am one satisfied customer with you',
        icon: avatar4,
      },
      {
        name: 'aaa',
        content:
          "This app i must say is the best app to trade your giftcard. In the past, i was having problems of people cheating me when redeeming my gift card, they will lie that gift card is low and give me low amount but with this app, i am earning a lot. i suggest if you're having problems, better use this app, it's the best ever",
        icon: avatar7,
      },
      {
        name: 'aaa',
        content:
          "Been looking for an exchange app without stressful and mundane procedures for a while now, and i came across giftcardstonaira. At first, i was a bit skeptical but after giving it a shot, i can say it's one of the best and i highly recommend you reading this to give it a try. you won't regret it. ",
        icon: avatar2,
      },
      {
        name: 'aaa',
        content:
          "This app is so amazing, i'm a guy that do doubt a lot and i did also when i was introduced to this app, but after my first trial, my doubt for it disappeared and my love for the app increases in everyday trading i do with it. it's very reliable and fast in terms of crediting, i'm so happy finding this great app. i'm giving it a 5 star because it deserves it, go for Giftcardstonaira, it's the best so far",
        icon: avatar7,
      },
    ],
  }),
  mounted() {
    // this.animation()
  },
  methods: {}
}
</script>


<style lang="scss" scoped>
.bg {
  text-align: center;
  padding-top: 5rem;
  color: #fff;
  background: #f5f5fe;
  position: relative;
}

.slider-bg {
  width: 100%;
  // padding: 2.5rem 0rem;
  overflow: hidden;
  --gap: 1.5rem;
  gap: var(--gap);
  -webkit-user-select: none;
  user-select: none;

  .inner-bg {
    // margin: 100px auto;
    width: 5000px;
    // height: 200px;
    overflow: hidden;

    display: flex;
    flex-direction: row;

    animation: change 30s linear 0s infinite normal;
    /*动画元素，持续时间，速度，延迟时间，重复次数，是否往返*/

    .scroll-item {
      list-style: none;
      float: left;
      box-sizing: border-box;
      margin-left: 5rem;
    }
  }
}

.content-bg {
  min-width: 25rem;
  max-width: 30rem;
  padding: 1.5rem 2rem;
  background: #ffffff;
  border-radius: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-right: 1.5rem;

  .content {
    text-align: left;
    font-size: 1rem;
    line-height: 1.6rem;
    font-weight: 400;
    color: #333;
    padding-bottom: 1.25rem;
  }

  .title-bg {
    display: flex;
    flex-direction: row;
    align-content: center;
    gap: 0.5rem;

    .name {
      color: #040417;
      font-size: 1rem;
      text-align: left;
      font-weight: bold;
      line-height: 1rem;

      height: 2rem;
      line-height: 2rem;
      text-align: center;
    }

    .icon {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      margin-right: 0.5rem;
      border: 2px solid #D8D8FB;
    }
  }
}

@keyframes change {
  from {
    margin-left: 0;
  }

  to {
    margin-left: -1200px;
  }
}

.big-title {
  color: #040417;
  font-size: 2.25rem;
  font-weight: 600;
  text-align: center;
  padding: 0 20%;
  line-height: normal;
  margin-bottom: 30px;
}

.download-desc {
  color: #040417;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  justify-self: center;
  align-items: center;
  line-height: 1.5rem;
  text-align: center;
  font-size: 500;
  margin-top: 1rem;
  padding: 0 20%;
  line-height: normal;
  margin-top: 30px;
}


@media only screen and (max-width: 767px) {
  .inner-bg {
    padding: 1.7rem 0rem;
    --gap: 1rem;
  }
}
</style>
