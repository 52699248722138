<template>
  <div id="app">
    <router-view></router-view>
    <div v-if="isShow()" class="chat-control" @click="clickShowChat">
      <img src="./assets/server.png" alt="" srcset="">
    </div>
    <!-- <Chat v-if="isShowChat" @clickClose="clickHiddenChat" /> -->
  </div>
</template>

<script>

/**
 * home
 *  - logo slider  [done]
 *  - top 1
 *  - car desc
 *  - specil
 *  - Home Evaluation
 * Rate
 *  - top 
 *  - caculate
 *  - desc
 *  - question
 * Reward
 *  - top
 *  - Description
 *  - Questions
 *  
 */

/* eslint-disable */

import Chat from './components/Chat.vue'

export default {
  name: "App",
  components: {
    Chat
  },
  data: () => ({
    isShowChat: false
  }),
  methods: {
    clickShowChat() {
      // this.isShowChat = !this.isShowChat
      this.$router.push({ name: 'Contact' })
    },
    clickHiddenChat() {
      this.isShowChat = false
    },
    isShow() {
      return this.$route.path != '/contact'
    }
  }
};
</script>

<style scoped>
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

html,
body {
  font-family: Roobert, sans-serif !important;
  font-size: 16px; /* 初始字体大小 */ 
}

@media (min-width: 600px) {
  html {
    font-size: 16px; 

  }
}

.chat-control {
  position: fixed;
  right: 3rem;
  bottom: 3rem;
  background-color: rgb(83, 82, 236);
  padding: 1rem;
  border-radius: 50%;
  img {
    width: 3rem;
  }
}
</style>