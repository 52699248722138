import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);
const myCustomLightTheme = {
  dark: true,
  themes:{
      light: {
        primary: "#018786",
        secondary:"#018786",
        accent: "#018786", 
        error: "#018786",
        'background-color':'#333333'
      },
      dark: {
        primary: "#018786",
        secondary: "#018786",
        accent: "#018786",
        'background-color':'#333333'
  },
  wallet:{
    'background-color':'#333333'
  }
}};

export default new Vuetify({
  theme: {
    defaultTheme: "myCustomLightTheme",
    themes: {
        myCustomLightTheme
    }
}
});
