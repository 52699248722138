<template>
  <div
    class="btn"
    @click.prevent.stop = "clickOpen"
  >
    <svg v-if="icon === 'ios'" class="icon" aria-hidden="true">
      <use xlink:href="#icon-android"></use>
    </svg>
    <svg v-else-if="icon === 'android'" class="icon" aria-hidden="true">
      <use xlink:href="#icon-android"></use>
    </svg>
    <img v-else class="icon" src="../assets/icons/globe.svg" alt="示例SVG图像" />
    <a class="inner_title"> {{ title }} </a>
  </div>
</template>
<script>
import Config from '../URL_Config'
  export default {
    name: 'GetAppButton',
    props: {
      title: {
        type: String,
        default: 'get ios'
      },
      icon: {
        type: String,
        default: 'ios'
      }
    },
    data: () => ({}
    ),
    methods:{
      clickOpen() {
        console.log('open app')
        const {title} = this
        if (title && title.length > 0) {
          console.log('clickOpen', Config[title])
          const {appDownloadURL_Web, appDownloadURL_Android, appDownloadURL_iOS, Login, Register} = Config
          if (title.indexOf('iOS') >= 0) {
            window.open(appDownloadURL_iOS, '_blank')
            return
          }

          if (title.indexOf('Android') >= 0) {
            window.open(appDownloadURL_Android, '_blank')
            return
          }

          if (title.indexOf('Web') >= 0) {
            window.open(appDownloadURL_Web, '_blank')
            return
          }

          if (title.indexOf('Login') >= 0) {
            window.open(Login, '_blank')
            return
          }


          if (title.indexOf('Started') >= 0) {
            window.open(Register, '_blank')
            return
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.btn {
  padding: 1.5rem 1.5rem;
  display: flex;
  width: fit-content;
  gap: .75rem;
  align-items: center;
  justify-content: center;
  border: 1px solid #8483F2;
  border-radius: 6.25rem;
  background: #6C6BEF;
    cursor: pointer;
  text-decoration: none;
  height: 4rem;
}

.icon {
  height: 1.25rem;
  width: 1.25rem;
}

.inner_title {
    color: #fffffe;
    font-weight: 600;
    font-size: 1rem !important;
  }

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* 1rem = 32px */
@media only screen and (min-width: 320px){
    html {
        font-size: 86% !important; 
    }
}

@media only screen and (min-width: 360px){
    html {
        font-size: 96% !important; 
    }
}

@media only screen and (min-width: 375px){
    html {
        font-size: 100% !important; 
    }
}

@media only screen and (min-width: 414px){
    html {
        font-size: 109.6% !important; 
    }
}

@media only screen and (min-width: 750px){
    html {
      font-size: 200% !important; 
    }
}

@media only screen and (min-width: 1024px){
    html {
        font-size: 138% !important;
    }
}


</style>
