<template>
  <div>
    <div class="bg">
      <div class="middle">
        <h3 class="title-1">The Future <br /> of Gift Card <br /> Trading</h3>
        <p>{{ subTitle }}</p>
      </div>
      <div>
          <div class="init-app">
            <GetAppButton title="Get it on iOS" icon="ios" />
            <GetAppButton title="Get it on Android" icon="android" />
            <GetAppButton title="Get it on Web" icon="web" class="web-btn" />
          </div>
        </div>
    </div>
    <div class="left animation_from_left">
    </div>
    <div class="right animation_from_right">
    </div>
  </div>

</template>

<script>
import GetAppButton from "./GetAppButton.vue"
export default {
  name: "HomeTop",
  components: {
    GetAppButton
  },
  data() {
    return {
      title: 'The Future of Gift Card Trading',
      subTitle: 'Giftcardstonaira is the best and most trusted platform to trade your gift cards for instant cash. '
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../animation.scss';

.bg {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  background: #5352EC;
  box-shadow: 0 4px 4px #00000040;
  background-image: url(../assets/icons/pattern.svg);
  background-position: 1rem 1rem;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  margin-top: 4.125rem;
  // padding-top: 8.286rem;
}

.left .right {
  flex-grow: 0;
}

.middle {
  display: flex;
  flex-direction: column;
  align-content: center;

  padding-left:  10.714rem;
  padding-right:  10.714rem;
  padding-top:  4.286rem;

  h3 {
    color: #fffffe;
    font-size: 7.5rem;
    line-height: 7.6rem;
    font-weight: 900;
  }

  p {
    color: #c2c1f9;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 300;
    max-width: 30rem;
    text-align: center;
    align-self: center;
    margin-top: 1rem;
  }
}

.init-app {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 2rem;

}

.left {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: absolute;
  top: 15.25rem;
  left: 7rem;
  border: .375rem solid #8483F2;
  background-image: url(../assets/neig.svg);
  background-repeat: no-repeat;
  background-size: cover;

}

.right {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: absolute;
  top: 24.875rem;
  right: 7rem;
  border: .375rem solid #8483F2;
  background-image: url(../assets/ghana.svg);
  background-repeat: no-repeat;
  background-size: cover;
}


.web-btn {
  background-color: #EF9837;

  ::v-deep .inner_title {
    color: #040417;
  }
}


@media screen and (max-width: 480px) {
  .left {
    width: 3rem;
    height: 3rem;
    left: 1rem;
    top: 17rem;
  }

  .right {
    width: 3rem;
    height: 3rem;
    top: 17rem;
    right: 1rem;
  }

  .middle {
    h3 {
      font-size: 4rem;
      line-height: 4.217rem;
    }
  }
}

@media screen and (max-width: 991px) {
  .left {
    left: 5rem;
  }

  .right {
    right: 5rem;
  }
}

@media screen and (max-width: 1024px) {
  .left {
    left: 5rem;
  }

  .right {
    right: 5rem;
  }
}
</style>
