<template>
  <div class="rate">
    <div class="rate-bg">
      <div class="toggler">
        <button v-for="(button, index) in buttons" :key="index" class="toggle-btn" :class="{ active: button.active }"
          @click="handleButtonClick(button.id)">
          {{ button.title }}
        </button>
      </div>
      <div class="select-content">
        <p style="font-weight: 500;">Sub-category</p>
        <el-select v-model="inputDenominationID" placeholder="" style="width: 100%;" @change="inputValueChange">
          <el-option v-for="item in denominationListOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
          <div slot="empty" style="height: 1.125rem; line-height: 1.125rem; font-size: 0.875rem; text-align: center; color: #b3b3b3;;">Empty</div>
        </el-select>
      </div>
      <div style="width: 100%;">
        <p style="font-weight: 500;">Card Amount</p>
        <ElInput v-model="inputNumber" :controls="false"  @input="inputValueChange" style="width: 100%; text-align: left;" />
      </div>
      <div class="caculate-rect">
        <div class="result-rect">
          <h6>Cash Value</h6>
          <h3><span>{{ currencyType }}</span>{{ caculateValue }}</h3>
        </div>
        <div class="submit-rect">
          <a href="" @click.prevent.stop="clickSubmit">
            Submit
          </a>
          <p>Amount calculated based on current rate.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from '../pages/basemixin.js'
import Config from '../URL_Config'
export default {
  name: 'CaculateComponents',
  props: {
    cardList: {
      default: ''
    }
  },
  mixins: [mixin],
  computed:{
    denominationListOptions() {
      return this.denominationList.map(e => { return { label: e.denominationName, value: e.id } })
    }
  },
  data() {
    return {
      buttons: [
        { id: 5, active: true, title: 'Naira (₦)' },
        { id: 6, active: false, title: 'Ghana Cedis (GH₵)' },
      ],
      inputNumber: '',
      inputDenominationID: undefined,
      currencyType: '₦',
      currencyId: 5,
      caculateValue: ''
    }
  },
  methods: {
    cardTypeListLoad() {
      const {path} = this.$route
      console.log('[load] path', path)
      const indexConfig = {
        '/steam': 'Steam',
        '/google-play': 'GooglePlay',
        '/apple-itunes': 'Apple iTunes',
        '/amazon': 'Amazon'
      }

      const cardName = indexConfig[path]
      if (cardName === undefined) return
      const fIndex = this.cardTypeList.findIndex(e => e.cardName === cardName)
      if (fIndex >= 0) {
        const {id} = this.cardTypeList[fIndex]
        this.loadDenomination(id)
      }
    },
    handleButtonClick(id) {
      this.buttons.forEach(button => {
        console.log(button.id, '',  id)
        if (button.id === id) {
          button.active = true
        } else {
          button.active = false
        }
      })

      // 切换按钮状态的互斥处理
      this.currencyId = id
      if (id === 5) {
        this.buttons[1].active = !this.buttons[0].active
        this.currencyType = '₦'
      } else if (id === 6) {
        this.buttons[0].active = !this.buttons[1].active
        this.currencyType = '₵'
      }
      this.inputValueChange()
    },
    inputValueChange() {

      const pValue = parseFloat(this.inputNumber)
      console.log(pValue)
      if (isNaN(pValue)) {
        return
      }

      

      if (this.inputDenominationID === undefined) {
        return
      }
      const arrTmp = this.denominationList.filter(e => e.id === this.inputDenominationID)
      if (arrTmp === undefined || arrTmp.length <= 0) {
        return
      }
      const arrTmp2 = arrTmp[0].denominationRateAppVoList.filter(e => e.currencyType === this.currencyId)
      if (arrTmp2 === undefined || arrTmp2.length <= 0) {
        return
      }

      console.log('[caculate] ', arrTmp2[0].exchangeRate, '*', pValue)
      const value = arrTmp2[0].exchangeRate * pValue
      this.caculateValue = value.toFixed(2).toString()
    },
    caculate() {
      
    },
    clickSubmit() {
      console.log('aaa')
      window.open(Config.Register, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.rate {

  flex: 0 0 auto;
  width: 41.66666667%;
  margin-bottom: 1rem;

  .rate-bg {
    position: relative;
    // margin-top: -12rem;
    background: #fffffe;
    border: 0.75rem solid #f5f5fe;
    display: flex;
    justify-content: space-between;
    border-radius: 1.875rem;
    width: 100%;
    overflow: auto;
    padding: 3.125rem;
    flex-direction: column;
    gap: 3.5rem;
    align-items: flex-start;

    .toggler {
      display: flex;
      border-radius: .625rem;
      background: #F2F2F2;
      padding: .375rem;
      flex-direction: row;
      align-items: flex-start;
      gap: .75rem;
    }

    .caculate-rect {
      width: 100%;

      .result-rect {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 0.875rem;
        justify-content: center;

        h3 {
          color: #5352ec;
          font-weight: 600;
          font-size: 3.5rem;

          span {
            color: #ebebfd;
          }
        }

        h6 {
          font-weight: 400;
          font-size: 1rem;
          color: #040417;
        }
      }

      .submit-rect {
        display: flex !important;
        width: 100% !important;
        width: 100% !important;
        flex-direction: column !important;
        margin-top: 3rem !important;
        gap: 0.5rem !important;

        a {
          background: #5352ec;
          border-radius: 6.25rem;
          padding: 1rem 2rem;
          border: none;
          display: flex;
          flex-direction: row;
          justify-content: center;
          font-size: 1rem;
          color: #fafafe;
          font-weight: 500;
          align-items: center;
          gap: 0.625rem;
        }

        p {
          color: #999;
          font-size: 1rem;
          text-align: center;
        }
      }
    }
  }
}

.toggle-btn {
  background: none;
  border-radius: .625rem;
  padding: .875rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .625rem;
  border: none;
  text-align: center;
  color: #b3b3b3;
  font-size: .875rem;
}

.active {
  color: #000;
  background: #FFFFFF;
}


.select-content {
    width: 100%;
    p {
      margin-bottom: 0.5rem;
    }

  }

@media only screen and (max-width: 480px) {
  .toggle-btn {
    padding: 0.375rem 0.375rem 0.375rem 0.375rem;
  }

  .rate {
    .rate-bg {
      padding: 2.25rem 1.25rem;
      gap: 2rem;
    }
  }
}
</style>
