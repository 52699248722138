<template>
  <v-app>
    <Header></Header>
    <v-main>
      <HomeTop />
      <HomeLogoSlider />
      <HomeCardDesc />
      <HomeEvaluation />
    </v-main>
      <Footer />
  </v-app>
</template>

<script>

import HomeEvaluation from "../components/HomeEvaluation.vue";
import HomeLogoSlider from "../components/HomeLogoSlider.vue";
import HomeCardDesc from "../components/HomeCardDesc.vue";
import HomeTop from "../components/HomeTop.vue";
import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
export default {
  name: 'HomePage',
  components: {
    HomeTop,
    HomeEvaluation,
    HomeLogoSlider,
    HomeCardDesc,
    Footer,
    Header
  }
}
</script>
