// mixin.js


import { getCardTypeList, getDenominationList } from '../request/information'

export default {
  data() {
    return {
      cardTypeList:[],
      denominationList: [],
    }
  },
  methods: {
    // 存储数据到localStorage
    saveToLocalStorage(key, value) {
      localStorage.setItem(key, JSON.stringify(value));
    },
    // 从localStorage获取数据
    getFromLocalStorage(key, defaultValue) {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : defaultValue;
    },
    loadCards() {
      getCardTypeList().then(response => {
        this.cardTypeList = response.data
        if (this.cardTypeListLoad) {
          this.cardTypeListLoad()
        }
      })
    },
    loadDenomination(e) {
      getDenominationList(e).then(response => {
        this.denominationList = response.data
      })
    },
  },
  mounted() {
    this.loadCards()
  },
};