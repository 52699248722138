<template>
  <v-app>
    <Header></Header>
    <v-main>
      <div>
        <div class='top-rect'>
          <h3>The Best App For Gift Card Trading</h3>
          <p>Giftcardstonaira is the best app to redeem, sell, and exchange gift cards. Our app is designed to make the
            process of exchanging your gift cards simple and hassle-free.</p>
          <div class="down-rect">
            <GetAppButton title="Get it on iOS" icon="ios" />
            <GetAppButton title="Get it on Android" icon="android" />
          </div>
        </div>
        <div class="down-info-bg">
          <div class="down-info-item">
            <div class="img animation_from_left">
              <img src="../assets/mock_1.png" alt="">
            </div>
            <div class="desc animation_from_bottom_50">
              <h3>{{ item0.title }} <br /> {{ item0.title1 }}</h3>
              <p>{{ item0.content }}</p>
              <a @click="clickStart">Get Started</a>
            </div>
          </div>

          <div class="down-info-item" style="flex-direction: row-reverse;">
            <div class="img animation_from_right">
              <img src="../assets/mock_2.png" alt="">
            </div>

            <div class="desc animation_from_bottom_50">
              <h3>{{ item1.title }} <br /> {{ item1.title1 }}</h3>
              <p>{{ item1.content }}</p>
              <a @click="clickStart">Get Started</a>
            </div>
          </div>

          <div class="down-info-item">
            <div class="img animation_from_left">
              <img src="../assets/mock_3.png" alt="">
            </div>
            <div class="desc animation_from_bottom_50">
              <h3>{{ item2.title }} <br /> {{ item2.title1 }}</h3>
              <p>{{ item2.content }}</p>
              <a @click="clickStart">Get Started</a>
            </div>
          </div>

        </div>
        <div class="ask-rect">
          <div class="left">
            <h3>
              Frequently Asked Questions About Giftcardstonaira
            </h3>
            <p>We answered some of the questions you might have.</p>
          </div>
          <div class="right">
            <el-collapse accordion>
              <el-collapse-item name="1" class="unsetbgcolor">
                <template slot="title">
                  <div class="collapse-title">Hope it's not a scam?</div>
                </template>
                <div class="collapse">We take great pride in the longevity of our business, which we've built over the
                  years. We hold
                  our reputation in high esteem and would never do anything to tarnish it hastily. Our customers'
                  satisfaction is of
                  utmost importance to us and remains at the forefront of our business practices.</div>
              </el-collapse-item>
              <el-collapse-item name="2" class="unsetbgcolor">
                <template slot="title">
                  <div class="collapse-title">Which gift card has the highest rate</div>
                </template>
                <div class="collapse"> At the moment, Steam, Amex, and Razer gift cards have the highest rates. However,
                  please note
                  that our rates are subject to change based on market trends and demand. We recommend checking our RATE
                  CALCULATOR or
                  contacting our customer service team for the latest rates. </div>
              </el-collapse-item>
              <el-collapse-item name="3" class="unsetbgcolor">
                <template slot="title">
                  <div class="collapse-title">How fast will I get paid?</div>
                </template>
                <div class="collapse"> You will typically receive payment within 5-10mins. We strive to process payments
                  as quickly as
                  possible to ensure a seamless experience for our users. Rest assured that we prioritize prompt payment
                  delivery to
                  ensure your funds reach you in a timely manner. </div>
              </el-collapse-item>
              <el-collapse-item name="4" class="unsetbgcolor">
                <template slot="title">
                  <div class="collapse-title">How can I get rewarded for trading gift card?</div>
                </template>
                <div class="collapse"> There are multiple rewarding opportunities on our platform. The top two methods
                  to
                  earn reward
                  points are trading gift cards, offering points for each transaction, and referring others, granting
                  you
                  points for
                  their transactions. Additionally, our leaderboard system, prediction/poll challenges, and promo codes
                  provide
                  further avenues for rewards. Start earning today! </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </div>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>

import Footer from "../components/Footer.vue";
import Header from "../components/Header.vue";
import GetAppButton from "../components/GetAppButton.vue"
import Config from '../URL_Config'
export default {
  name: 'RewardPage',
  components: {
    Footer,
    Header,
    GetAppButton
  },
  data() {
    return {
      item0: {
        title: 'Redeem Up To 25+',
        title1: 'Gift Cards with Ease',
        content: 'With our app, you can easily redeem many gift cards for cash(Naira or Cedis). Whether you have an Amazon gift card, Steam card, Google Play, Apple iTunes or any other type of gift card, our app makes it simple and easy to get the best value for your gift cards.'
      },
      item1: {
        title: 'High Rates or',
        title1: 'Nothing',
        content: 'Our app offers the highest gift card rates in Nigeria and a simple, easy-to-use interface. Our gift card rate calculator gives you access to the check the cards with the highest rates today.'
      },
      item2: {
        title: 'Get Rewarded All',
        title1: 'Day',
        content: 'Giftcardstonaira offers you free trading points on every gift card sale and you can also earn money from our leaderboard system, poll challenges and referral system.'
      }
    }
  },
  methods:{
    clickStart() {
      console.log('click start')
      window.open(Config.Register, '_blank')
    }
  }
}
</script>


<style lang="scss" scoped>
@import '../animation.scss';


.down-info-bg {
  padding: 3.667rem;
}

.down-info-item {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  .img {
    background-color: none;
    border-radius: 1.25rem;
    width: 50%;
    max-height: 45rem;
    height: 45rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2rem;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
      border-radius: 1.25rem;
    }
  }

  .desc {
    background-color: none;
    border-radius: 1.25rem;
    width: 50%;
    max-height: 45rem;
    height: 45rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2rem;

    h3 {
      margin-top: 2rem;
      font-weight: 600;
      font-size: 2.5rem;
      text-align: left;
      color: #040417;
      line-height: 2.8rem;
    }

    p {
      margin-top: 1rem;
      font-size: 1rem;
      text-align: left;
      color: #333;
      line-height: 1.6rem;
      font-weight: 400;
    }

    a {
      padding: .875rem 1.5rem;
      border-radius: 6.25rem;
      background: #5352EC;
      display: flex;
      cursor: pointer;
      justify-content: center;
      width: -moz-fit-content;
      width: fit-content;
      align-items: center;
      text-decoration: none;
      color: #fffffe;
      font-size: .875rem;
      margin-top: 3rem !important;
    }
  }
}

.top-rect {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  background: #5352EC;
  // background-image: url(pattern.3ba50c275ea855d9.svg);

  background-position: 1rem 1rem;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  margin-top: 4.125rem;
  padding: 8.286rem 10.714rem 4.286rem;

  h3 {
    color: #fffffe;
    font-size: 7.5rem;
    line-height: 7.6rem;
    font-weight: 900;
  }

  p {
    color: #c2c1f9;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 300;
    max-width: 43.313rem;
  }

  .down-rect {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 2rem;
  }
}


.ask-rect {

  text-align: left;
  padding: 6rem 6rem 20rem;
  background: #312ED2;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem 4rem;

  .left {
    width: 33%;

    h3 {
      color: #fffffe;
      font-size: 3rem;
      font-weight: 600;
      line-height: 3.513rem;
      text-align: left;
      line-height: 3.4rem;

      margin-top: 0;
      margin-bottom: .5rem;
    }

    p {

      color: #c2c1f9;
      font-size: 1rem;
      margin-top: 1.2rem;
      text-align: left;
      line-height: 1.6rem;

    }
  }

  .right {
    width: 60%;


    .collapse {
      padding: 2rem;
      background: #2C29BC;
      font-size: 1rem;
      color: #fffffe;
      line-height: 1.9rem;
      font-weight: 400;
    }


    .collapse-title {
      font-size: 1.2rem;
      font-weight: 400;
      color: #fffffe;
      text-align: left;
      padding: 1.5rem 1.5rem;

      background-color: transparent;
      box-shadow: none;
    }


    .unsetbgcolor {
      :deep(.el-collapse-item__header) {
        background-color: unset;
      }

      :deep(.el-collapse-item__content) {
        background-color: #2C29BC;
        padding-bottom: 0px;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .top-rect {
    margin-top: 3.5rem;
    padding: 4.75rem 2.5rem 2.5rem;

    h3 {
      font-size: 4rem;
      line-height: 4.217rem;
      font-weight: 900;
    }

    p {
      font-size: 1.2rem;
      line-height: 1.5rem;
      font-weight: 400;
    }
  }

  .ask-rect {
    padding: 3rem 1rem 20rem;

    .left {
      width: 100%;

      h3 {
        font-size: 2rem;
        line-height: normal;
        text-align: center;
      }

      p {
        text-align: center;
      }
    }

    .right {
      width: 100%;
    }
  }

  .down-info-bg {
    gap: 1.5rem;
    padding: 1rem 1.6rem 0rem;


    .img {
      width: 100%;
      padding: 0;
      height: auto;
      min-height: unset;
    }

    .desc {
      width: 100%;
      padding: 0;
      margin-top: 2rem;
      margin-bottom: 2rem;
      height: unset;

      h3 {
        font-weight: 600;
        text-align: left;
        color: #040417;
        line-height: 2.8rem;
        font-size: 1.5rem;
        margin-top: 0;
      }

      p {
        margin-top: 1rem;
        font-size: 1rem;
        text-align: left;
        color: #333;
        line-height: 1.6rem;
        font-weight: 400;

      }

      a {
        padding: .875rem 1.5rem;
        border-radius: 6.25rem;
        background: #5352EC;
        display: flex;
        cursor: pointer;
        justify-content: center;
        width: -moz-fit-content;
        width: fit-content;
        align-items: center;
        text-decoration: none;
        color: #fffffe;
        font-size: .875rem;
        margin-top: 3rem !important;
        margin-bottom: 2rem;
      }

    }
  }
}
</style>
