<template>
  <v-app>
    <Header></Header>
    <v-main>
      <div class="bg-rect">
        <div class="row">
          <div class="row-left">
            <div class="rect-title">
              <h3>Looking For<br />Where To Sell Your Steam Gift Cards?</h3>
              <p>Giftcardstonaira is the best app and website to sell your gift cards at high rates for instant cash.
              </p>
            </div>
            <img v-if="this.$route.name === 'Steam'" src="../assets/steam.png" alt="">
            <img v-if="this.$route.name === 'Google'" src="../assets/google_play.png" alt="">
            <img v-if="this.$route.name === 'Apple'" src="../assets/itunes.png" alt="">
            <img v-if="this.$route.name === 'Amazon'" src="../assets/amazon.png" alt="">
          </div>
          <Caculate class="row-right"  :cardList="denominationList"/>
        </div>
      </div>
      <div class="detial-section">
        <div>
          <h3>{{ aboutOptions[sellIndex].title }}</h3>
          <p>{{ aboutOptions[sellIndex].content }}</p>
        </div>
        <div>
          <h3>How To Sell Gift Cards on Giftcardstonaira</h3>
          <p>If you want to sell your gift card, you can use our Web App or download our Mobile App then follow below
            step-by-step guide.</p>
        </div>
        <div class="list">
          <div v-for="(item, index) in descArr" :key="index" class="desc-bg">
            <div class="number">{{ index + 1 }}</div>
            <span class="desc">{{ item }}</span>
          </div>
        </div>
        <div>
          <h3>How Much Is Gift Cards In Naira Today?</h3>
          <p>You're probably wondering how much is a $100 Steam, Google Play, Amazon, Apple iTunes in Naira today, well
            the prices range from 58,000 to 78,000 Naira. On Giftcardstonaira, we pride ourselves in providing the
            highest
            rates for all gift cards. So if you're looking to exchange your gift cards for cash, then we're your best
            bet.
          </p>
        </div>
      </div>
      <div class="ask-rect">
        <div class="left">
          <h3>
            Frequently Asked Questions About Giftcardstonaira
          </h3>
          <p>We answered some of the questions you might have.</p>
        </div>
        <div class="right">
          <el-collapse accordion v-model="activeNames">
            <el-collapse-item name="1" class="unsetbgcolor">
              <template slot="title">
                <div class="collapse-title">Hope it's not a scam?</div>
              </template>
              <div class="collapse">We take great pride in the longevity of our business, which we've built over the
                years. We hold
                our reputation in high esteem and would never do anything to tarnish it hastily. Our customers'
                satisfaction is of
                utmost importance to us and remains at the forefront of our business practices.</div>
            </el-collapse-item>
            <el-collapse-item name="2" class="unsetbgcolor">
              <template slot="title">
                <div class="collapse-title">Which gift card has the highest rate</div>
              </template>
              <div class="collapse"> At the moment, Steam, Amex, and Razer gift cards have the highest rates. However,
                please note
                that our rates are subject to change based on market trends and demand. We recommend checking our RATE
                CALCULATOR or
                contacting our customer service team for the latest rates. </div>
            </el-collapse-item>
            <el-collapse-item name="3" class="unsetbgcolor">
              <template slot="title">
                <div class="collapse-title">How fast will I get paid?</div>
              </template>
              <div class="collapse"> You will typically receive payment within 5-10mins. We strive to process payments
                as quickly as
                possible to ensure a seamless experience for our users. Rest assured that we prioritize prompt payment
                delivery to
                ensure your funds reach you in a timely manner. </div>
            </el-collapse-item>
            <el-collapse-item name="4" class="unsetbgcolor">
              <template slot="title">
                <div class="collapse-title">How can I get rewarded for trading gift card?</div>
              </template>
              <div class="collapse"> There are multiple rewarding opportunities on our platform. The top two methods to
                earn reward
                points are trading gift cards, offering points for each transaction, and referring others, granting you
                points for
                their transactions. Additionally, our leaderboard system, prediction/poll challenges, and promo codes
                provide
                further avenues for rewards. Start earning today! </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Footer from '../components/Footer.vue'
import Header from "../components/Header.vue";
import Caculate from "../components/Caculate.vue";

export default {
  name: 'SallCardPage',
  components: {
    Footer,
    Header,
    Caculate
  },
  data() {
    return {
      title: 'The Future of Gift Card Trading',
      subTitle:
        'Giftcardstonaira is the best and most trusted platform to trade your gift cards for instant cash. ',
      descArr: [
        'Create an account or Sign in if you already have an account.',
        'Go to Wallet and add your Nigerian Bank Account or MOMO Number.',
        'Go to the main menu and click Sell Gift Card.',
        'Select Gift Card category and a subcategory.',
        'Enter value of the gift card and upload image (or type the ecode)',
        'Submit trade and wait for confirmation.',
      ],
      inputNumber: '',
      aboutOptions: [{
        title: 'About Steam Gift Card',
        content: 'Steam cards are gift cards that can be redeemed for Steam credits. Using the Steam gift card, gamers can purchase games, downloadable content, and in-game purchases. Typically, physical Steam cards have a value of $20, $30, $50, and $100 while the digital cards are available in denominations of $5, $10, $25, $50, and $100. '
      }, {
        title: 'About Google Play Gift Card',
        content: 'Google Play gift cards are cards issued by Google that can be used to pay for games, apps, books, movies, and songs in the Google Play store. They can come as physical cards or as an ecode which is delivered via email '
      }, {
        title: 'About Apple iTunes Gift Card',
        content: 'Apple gift cards are used for the purchase of goods and services on several apple platforms like Apple Music, Apple Store, the Apple Store, Apple TV and more. On Giftcardstonaira, you can exchange your apple gift card to naira at the highest rates. '

      }, {
        title: 'About Amazon Gift Card',
        content: 'The Amazon Gift Card is a type of gift card issued by the Amazon.com which is redeemable only on Amazon and its affiliates platforms or stores. These card are preloaded with funds and they have instant discounts for all products available on Amazon store. '
      }],
      activeNames: ['1'],
      sellIndex: 0,
      denominationList: []
    }
  },
  watch: {
    '$route'(to, from) {
      console.log(to.path)
      const indexConfig = {
        '/steam': 0,
        '/google-play': 1,
        '/apple-itunes': 2,
        '/amazon': 3
      }

      this.sellIndex = indexConfig[to.path] || 0
    }
  },
  mounted() {

  },
  methods: {
    handleButtonClick(id) {
      this.buttons.forEach(button => {
        if (button.id === id) {
          button.active = true
        } else {
          button.active = false
        }
      })

      // 切换按钮状态的互斥处理
      if (id === 'button1') {
        this.buttons[1].active = !this.buttons[0].active
      } else if (id === 'button2') {
        this.buttons[0].active = !this.buttons[1].active
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.bg-rect {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: flex-start;
  justify-content: flex-start;
  background: #5352EC;
  // background-image: url(pattern.3ba50c275ea855d9.svg);
  background-repeat: no-repeat;
  background-size: cover;
  text-align: left;
  margin-top: 2.143rem;
  padding: 8.286rem 4.25rem 0;
}

.detial-section {
  padding: 2rem 6.667rem 6.667rem 3.667rem;
  display: flex;
  flex-direction: column;
  gap: 5rem;

  h3 {
    color: #040417;
    font-size: 3rem;
    font-weight: 600;
    line-height: 3.513rem;
    text-align: center;
    padding: 0 20%;
    line-height: normal;
  }

  p {
    color: #040417;
    font-size: 1rem;
    margin-top: 1.2rem;
    padding: 0 30%;
    text-align: center;
    line-height: 1.6rem;
  }

  .list {
    padding: 0 10%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 2.5rem 2rem;

    .desc-bg {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 2rem;
      gap: 1rem 0;
      width: 30.303030303%;
      height: 13rem;
      max-height: 16rem;
      overflow: hidden;
      text-overflow: ellipsis;
      background: #fafafe;
      border: 2px solid #e1e1fc;
      border-radius: 1.25rem;
    }
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 2.5rem 2rem;

  .desc-bg {
    padding: 2rem;
    gap: 1rem 0;
    width: 30.303030303%;
    height: 13rem;
    max-height: 16rem;
    overflow: hidden;
    text-overflow: ellipsis;
    background: #fafafe;
    border: 2px solid #e1e1fc;
    border-radius: 1.25rem;

    .number {
      font-weight: 600;
      font-size: 1.2rem;
      text-align: left;
      color: #040417;
    }

    .desc {
      font-size: 1.2rem;
      text-align: left;
      color: #333;
      line-height: 1.6rem;
      font-weight: 400;
    }
  }
}

.rect-title {
  z-index: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  background: #5352ec;
  // background-image: url(pattern.svg);
  background-position: 1rem 1rem;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: left;
  padding-right: 2rem;

  margin-top: 2.143rem;

  h3 {
    color: #fffffe;
    font-size: 3.5rem;
    line-height: 3.9rem;
    font-weight: 900;
    text-align: left;
  }

  p {
    color: #c2c1f9;
    font-size: 1.2rem;
    line-height: normal;
    font-weight: 400;
  }
}


.row {

  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y));
  margin-right: calc(-.5* var(--bs-gutter-x));
  margin-left: calc(-.5* var(--bs-gutter-x));

  .row-left {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .row-right {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x)* .5);
  padding-left: calc(var(--bs-gutter-x)* .5);
  margin-top: var(--bs-gutter-y);
}

.ask-rect {

  text-align: left;
  padding: 6rem 6rem 20rem;
  background: #F5F5FE;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem 4rem;
  padding: 3.75rem;


  .left {
    width: 33%;

    h3 {
      color: #040417;
      font-size: 3rem;
      font-weight: 600;
      line-height: 3.513rem;
      text-align: left;
      line-height: 3.4rem;

      margin-top: 0;
      margin-bottom: .5rem;
    }

    p {

      color: #c2c1f9;
      color: #040417;
      font-size: 1rem;
      margin-top: 1.2rem;
      text-align: left;
      line-height: 1.6rem;

    }
  }

  .right {
    width: 60%;


    .collapse-title {
      font-size: 1.2rem;
      font-weight: 400;
      color: #040417;
      text-align: left;
      padding: 1.5rem 1.5rem;
    }

    .collapse {
      padding: 2rem;
      background: #EBEBFD;
      font-size: 1rem;
      color: #333;
      line-height: 1.9rem;
      font-weight: 400;
    }

    .unsetbgcolor {
      :deep(.el-collapse-item__header) {
        background-color: unset;
      }
    }

    .title {
      // color: #fffffe;
      // fill: #fffffe;
      background-color: transparent;
      box-shadow: none;
    }

    .content {
      padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
      background: #2C29BC;
      font-size: 1rem;
      // color: #fffffe;
      line-height: 1.9rem;
      font-weight: 400;
    }
  }
}

:deep(.el-collapse-item__content) {
  padding-bottom: 0px;
}

@media only screen and (max-width: 480px) {
  .bg-rect {
    margin-top: 3.5rem;
    padding: 4.75rem 2rem 0;

    .row {
      .row-left {
        width: 100%;

        .rect-title {
          h3 {
            font-size: 2.5rem;
            line-height: normal;
          }

          p {
            font-size: 1.2rem;
            line-height: normal;
            font-weight: 400;
          }
        }

      }

      .row-right {
        width: 100%;
      }
    }
  }

  .detial-section {
    gap: 4rem;
    padding: 3rem 1.6rem 1.5rem;

    h3 {
      padding: 0 7%;
      font-size: 2rem;
      font-weight: 600;
      line-height: normal;
    }

    p {
      font-size: 1rem;
      padding: 0 3%;

    }

    .list {
      padding: 0;

      .desc-bg {
        width: 45.4545454545%;
        height: 14rem;
        padding: 1rem;
      }
    }
  }


  .ask-rect {
    padding: 3rem 1rem 3rem;

    .left {
      width: 100%;

      h3 {
        font-size: 2rem;
        line-height: normal;
        text-align: center;
      }

      p {
        text-align: center;
      }
    }

    .right {
      width: 100%;
    }
  }
}
</style>
