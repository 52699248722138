<template>
  <div class="chat-window" :style="bgStyle">
      <div class="chat-head">
          <div class="name"></div>
          <div class="title">Migo</div>
          <svg style="cursor: pointer;" @click="clickHiddenChat(false)" xmlns="http://www.w3.org/2000/svg" width="29"
              height="29" viewBox="0 0 29 29" fill="none">
              <path d="M7.07129 7.07129L21.2134 21.2134" stroke="#666666" stroke-linecap="round" />
              <path d="M7.07129 21.2139L21.2134 7.07173" stroke="#666666" stroke-linecap="round" />
          </svg>
      </div>
      <!-- 显示聊天消息的容器 -->
      <div class="message-container" ref="container">
        <div style="font-size: 16px;font-weight: 600;transition: 300ms;color: rgb(0, 0, 0);text-align: center;">We typically reply in under a minute</div>
        <div style="text-align: center;color: #666;">{{getNowTime()}}</div>
          <div v-for="(message, i) in messages" :key="i" class="message">
              <div v-if="message.type === 2" class="message_item-inner mine-inner">
                  <span class="message-text mine">
                      {{ message.text }}
                  </span>
              </div>
              <div v-else-if="message.type === 0" class="message_item-inner">
                  <!-- <img class="icon" src="../assets/sever.png" alt=""> -->
                  <!-- <div class="icon"></div> -->
                  <div class="message-text">
                    <span>
                      {{ message.text }}
                    </span>
                  </div>
              </div>
              <div v-else class="message_item-inner">
                  <div style="border: 1px solid #E9EFFF;padding: 15px;border-radius: 5px;">
                    <div style="margin-bottom: 5px;">Get notified by email</div>
                    <el-input v-model="inputEmail" placeholder="email@example.com">
                      <template slot="append">
                        <el-button @click="clickSubmitEmail">Submit</el-button>
                      </template>
                    </el-input>
                  </div>
              </div>
          </div>
      </div>
      <!-- 输入消息的表单 -->
      <div class="input-form">
          <div class="inputText">
              <el-input @keyup.enter.native="sendMessage" :disabled="inputTextDis" v-model="inputText"
                  placeholder="Message..." />
          </div>
          <img class="send" @click="sendMessage" src="../assets/send.png" alt="">
      </div>
  </div>
</template>

<script>
// import { GetCallcenterLink } from '@/api/newhome/index.js'
// type 0 自动返回信息
// type 1 输入内容 emil
// type 2 客户输入
export default {
  name: 'ChatWorld',
  data() {
      return {
          inputText: '',
          messages: [
              // { text: 'We typically reply in under a minute!', type: 0 },
          ],
          inputTextDis: false,
          inputEmail: '',
          isSendFirst: true,
          bgStyle: {}
      };
  },
  mounted() {
    this.checkWidth();
    window.addEventListener('resize', this.checkWidth);
  },
  destroyed() {
    window.removeEventListener('resize', this.checkWidth);
  },
  methods: {
    checkWidth() {

       // 系统整体缩放
    let innerWidth = document.documentElement.clientWidth || document.body.clientWidth
    let innerHeight = document.documentElement.clientHeight || document.body.clientHeight

      // const { innerWidth, innerHeight } = window
      if (innerWidth > 768) {
        this.bgStyle = {
          width: '470px',
          height: '600px',
          'border-radius': '10px',
          position: 'fixed',
          right: '3rem',
          bottom: '9rem'
        }
      } else {
        this.bgStyle = {
          width: `${innerWidth}px`,
          height: `${innerHeight}px`,
          position: 'fixed',
          left: '0rem',
          top: '0rem',
        }
      }
    },
    getNowTime() {
      const now = new Date()
      const m = ['January','February','March','April','May','June','July','August','September','October','November','December']
      return `${m[now.getMonth()]} ${now.getDate()}`
    },
    sendMessage() {
        if (this.inputText.trim()) {
            this.messages.push({ id: Date.now(), text: this.inputText, type: 2 });
            const data = {
                "type": "interface",
                "name": "show",
                "params": {
                    "text": this.inputText,
                    "voice": "xiaomei"
                }
            }
            this.inputText = '';

            if (this.isSendFirst) {
              setTimeout(() => {
                this.messages.push({ text: 'Migo typically replies in under 1m.', type: 0 },)
                setTimeout(() => {
                  this.messages.push({ text: 'Give the team a way to reach you:', type: 0 },)
                  this.messages.push({ text: '', type: 1 },)
              }, 500);

              }, 1000);
            }

            this.isSendFirst = false
          
            this.$nextTick(() => {
                // dom 元素更新后执行滚动条到底部 否则不生效
                let scrollElem = this.$refs.container;
                scrollElem.scrollTop = scrollElem.scrollHeight
            });
        }
    },
    clickSubmitEmail() {

    },
    clickHiddenChat() {
      this.$emit('clickClose')
    }
  },
};
</script>

<style scoped lang="scss">

.chat-window {
  width: 470px;
  height: 600px;
  margin: 0 auto;
  position: relative;
  // background: url('../static/img/ai/Group3.png');
  // background-size: 100% 100%;
  background: #fff;
  border-radius: 10px;
  box-shadow: 8px 8px 20px rgba(55, 99, 170, .1), -2px -2px 16px rgba(55, 99, 170, .1);
  overflow: hidden;
  z-index: 1031;

  .input-form {
      position: absolute;
      bottom: 28px;
      display: flex;
      align-items: center;
      padding: 0 15px;
      width: 100%;

      .inputText {
          flex-grow: 1;
          height: 44px;
          border-radius: 22px;
          background: #E9EFFF;
          display: flex;
          padding: 0 20px;

          ::v-deep .el-input {
              background: #E9EFFF;
              border: 0;

              .el-input__inner {
                  background: #E9EFFF;
                  width: 100%;
                  height: 100%;
                  border: 0;
              }
          }
      }

      .send {
          margin-left: 10px;
          width: 40px;
          height: 40px;
          cursor: pointer;
          flex-grow: 0;
      }
  }
}

.chat-head {
  width: 100%;
  height: 54px;
  background: rgba(255, 255, 255, 0.80);
  box-shadow: 0px 4px 4px 2px rgba(232, 235, 243, 0.50);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;

  .title {
      color: #000;
      font-family: Source Han Sans CN;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-left: 40px;
  }
}

.message-container {
  margin-bottom: 10px;
  width: 100%;
  height: 474px;
  overflow-y: auto;
  padding: 0 18px;
  padding-top: 30px;

  .message {
      padding: 5px;
      // margin-top: 40px;
      // direction: rtl
      .message_item-inner {
          width: 100%;
          display: flex;
          align-items: flex-start;

          .icon {
              width: 48px;
              height: 48px;
              margin-right: 8px;
          }
      }

      .mine-inner {
          width: 100%;
          direction: rtl;

          .icon {
              margin-right: 0;
              margin-left: 8px;
          }
      }
  }

  .message-text {
      display: inline-block;
      max-width: 290px;
      padding: 10px;
      border-radius: 5px;
      background: #E9EFFF;
      color: #666;
      font-family: Source Han Sans CN;
      font-size: 16px;
      font-weight: 400;
      word-wrap: break-word;
      text-align: left;
      position: relative;
  }
  
  .mine {
      background-color: rgb(83, 82, 236);
      color: white;
      direction: ltr;
  }
}
</style>