<template>
  <v-app>
    <Header></Header>
    rate
    <v-main>
      <div class="rect-title">
        <h3>Gift Card Rate<br />Calculator</h3>
        <p>Find out the current rate of different gift cards in Naira or Cedis using GitfCardsToNaira's gift card rate
          calculator.</p>
      </div>

      <div class="rate">
        <div class="rate-bg">
          <div class="toggler">
            <button v-for="(button, index) in buttons" :key="index" class="toggle-btn"
              :class="{ active: button.active }" @click="handleButtonClick(button.id)">
              {{ button.title }}
            </button>

          </div>
          <div style="width: 100%;">
            <p style="font-weight: 500;margin-top: 1rem;margin-bottom: 0.5rem;">Select Gift card</p>
            <el-select v-model="inputCardTypeID" placeholder="" style="width: 100%;" no-data-text="no data"
              @change="loadDenomination">
              <el-option v-for="item in cardTypeListOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div style="width: 100%;">
            <p style="font-weight: 500;margin-top: 1rem;margin-bottom: 0.5rem;">Sub-category</p>
            <el-select v-model="inputDenominationID" placeholder="" style="width: 100%;" no-data-text="no data"
              @change="denominationChange">
              <el-option v-for="item in denominationListOptions" :key="item.value" :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div style="width: 100%;">
            <p style="font-weight: 500;margin-top: 1rem;margin-bottom: 0.5rem;">Card Amount</p>
            <ElInput v-model="inputNumber" autocomplete="off" @input="inputValueChange" :controls="false"
              style="width: 100%; text-align: left;" />
          </div>
          <div class="caculate-rect">
            <div class="result-rect">
              <h6>Cash Value</h6>
              <h3><span>{{ currencyTypeDesc }}</span>{{ caculateValue }}</h3>
            </div>
            <div class="submit-rect">
              <a href="" @click="clickSubmit">
                Submit
              </a>
              <p>Amount calculated based on current rate.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="detial-section">
        <div class="detail-top">
          <h3>How To Sell Gift Cards on Giftcardstonaira</h3>
          <p>If you want to sell your gift card, you can use our Web App or download our Mobile App then follow below
            step-by-step guide.</p>
        </div>
        <div class="list">
          <div v-for="(item, index) in descArr" :key="index" class="desc-bg">
            <div class="number">{{ index + 1 }}.</div>
            <span class="desc">{{ item }}</span>
          </div>
        </div>
        <div>
          <h3>How Much Is Gift Cards In Naira Today?</h3>
          <p>You're probably wondering how much is a $100 Steam, Google Play, Amazon, Apple iTunes in Naira today, well
            the prices range from 58,000 to 78,000 Naira. On Giftcardstonaira, we pride ourselves in providing the
            highest
            rates for all gift cards. So if you're looking to exchange your gift cards for cash, then we're your best
            bet.
          </p>
        </div>
      </div>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Footer from '../components/Footer.vue'
import Header from "../components/Header.vue";
import axios from "axios";
import { getCardTypeList, getDenominationList } from '../request/information'
import {
  CardList,
  DenominationList,
  CurrencyList
} from '../request/data'
import mixin from './basemixin.js'
import Config from '../URL_Config'
export default {
  name: 'RatePage',
  components: {
    Footer,
    Header,
  },
  mixins: [mixin],
  data() {
    return {
      buttons: [
        { id: 5, active: true, title: 'Naira (₦)', pre: '₦' },
        { id: 6, active: false, title: 'Ghana Cedis (GH₵)', pre: 'GH₵' },
      ],
      title: 'The Future of Gift Card Trading',
      subTitle:
        'Giftcardstonaira is the best and most trusted platform to trade your gift cards for instant cash. ',
      descArr: [
        'Create an account or Sign in if you already have an account.',
        'Go to Wallet and add your Nigerian Bank Account or MOMO Number.',
        'Go to the main menu and click Sell Gift Card.',
        'Select Gift Card category and a subcategory.',
        'Enter value of the gift card and upload image (or type the ecode)',
        'Submit trade and wait for confirmation.',
      ],
      currencyID: 5,
      currencyTypeDesc: '₦',
      inputCardTypeID: '',
      inputDenominationID: undefined,
      inputNumber: '',
      cardTypeList: [],
      denominationList: [],
      inputValue: 0.0,
      caculateValue: '0.00'
    }
  },
  computed: {
    cardTypeListOptions() {
      return this.cardTypeList.map(e => { return { label: e.cardName, value: e.id } })
    },
    denominationListOptions() {
      return this.denominationList.map(e => { return { label: e.denominationName, value: e.id } })
    }
  },
  mounted() {
    // this.loadCards()
  },
  methods: {
    // loadCards() {
    //   getCardTypeList().then(response => {
    //     this.cardTypeList = response.data
        
    //   })
    // },
    loadCurrencyType() {
    },
    
    handleButtonClick(id) {
      this.buttons.forEach(button => {
        if (button.id === id) {
          button.active = true
        } else {
          button.active = false
        }
      })

      this.currencyID = id

      // 切换按钮状态的互斥处理
      if (id === 6) {
        this.buttons[1].active = !this.buttons[0].active
        this.currencyTypeDesc = this.buttons[1].pre
      } else if (id === 5) {
        this.buttons[0].active = !this.buttons[1].active
        this.currencyTypeDesc = this.buttons[0].pre
      }
      this.calculate()
    },
    calculate() {
      if (this.inputDenominationID === undefined) {
        return
      }
      const arrTmp = this.denominationList.filter(e => e.id === this.inputDenominationID)
      if (arrTmp === undefined || arrTmp.length <= 0) {
        return
      }
      const arrTmp2 = arrTmp[0].denominationRateAppVoList.filter(e => e.currencyType === this.currencyID)
      if (arrTmp2 === undefined || arrTmp2.length <= 0) {
        return
      }

      const value = arrTmp2[0].exchangeRate * this.inputNumber
      this.caculateValue = value.toFixed(2).toString()
    },
    denominationChange() {
      this.calculate()
    },
    inputValueChange(e) {
      const pValue = parseFloat(e.trim())
      if (isNaN(pValue)) {
        return
      }
      this.inputValue = pValue
      this.calculate()
    },
    clickSubmit() {
      window.open(Config.Register, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.rate {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10%;
  margin-top: -12rem;

  .rate-bg {
    position: relative;
    background: #fffffe;
    border: 0.75rem solid #f5f5fe;
    display: flex;
    justify-content: space-between;
    border-radius: 1.875rem;
    width: 100%;
    overflow: auto;
    padding: 3.125rem;
    flex-direction: column;
    // gap: 3.5rem;
    align-items: flex-start;


  }
}

.toggler {
  display: flex;
  border-radius: .625rem;
  background: #F2F2F2;
  padding: .375rem;
  flex-direction: row;
  align-items: flex-start;
  gap: .75rem;
}


.toggle-btn {
  background: none;
  border-radius: .625rem;
  padding: .875rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .625rem;
  border: none;
  text-align: center;
  color: #b3b3b3;
  font-size: .875rem;
}

.active {
  color: #000;
  background: #FFFFFF;
}

.detial-section {
  padding: 2rem 6.667rem 6.667rem 3.667rem;
  display: flex;
  flex-direction: column;
  gap: 5rem;

  h3 {
    color: #040417;
    font-size: 3rem;
    font-weight: 600;
    line-height: 3.513rem;
    text-align: center;
    padding: 0 20%;
    line-height: normal;
  }

  p {
    color: #040417;
    font-size: 1rem;
    margin-top: 1.2rem;
    padding: 0 30%;
    text-align: center;
    line-height: 1.6rem;
  }

  .list {
    padding: 0 10%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 2.5rem 2rem;

    .desc-bg {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 2rem;
      gap: 1rem 0;
      width: 30.303030303%;
      height: 13rem;
      max-height: 16rem;
      overflow: hidden;
      text-overflow: ellipsis;
      background: #fafafe;
      border: 2px solid #e1e1fc;
      border-radius: 1.25rem;
    }
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 2.5rem 2rem;

  .desc-bg {
    padding: 2rem;
    gap: 1rem 0;
    width: 30.303030303%;
    height: 13rem;
    max-height: 16rem;
    overflow: hidden;
    text-overflow: ellipsis;
    background: #fafafe;
    border: 2px solid #e1e1fc;
    border-radius: 1.25rem;

    .number {
      font-weight: 600;
      font-size: 1.2rem;
      text-align: left;
      color: #040417;
    }

    .desc {
      font-size: 1.2rem;
      text-align: left;
      color: #333;
      line-height: 1.6rem;
      font-weight: 400;
      margin-bottom: 1.0rem;
    }
  }
}

.rect-title {
  z-index: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  background: #5352ec;
  // background-image: url(pattern.3ba50c275ea855d9.svg);
  background-position: 1rem 1rem;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  margin-top: 4.125rem;
  padding: 8.286rem 10.714rem 16.286rem;

  margin-top: 2.143rem;

  h3 {
    color: #fffffe;
    font-size: 7.5rem;
    line-height: 7.6rem;
    font-weight: 900;
  }

  p {
    color: #c2c1f9;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 300;
    max-width: 35rem;
  }
}

.caculate-rect {
  width: 100%;

  .result-rect {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 0.875rem;
    justify-content: center;
    margin-top: 1rem;

    h3 {
      color: #5352ec;
      font-weight: 600;
      font-size: 3.5rem;

      span {
        color: #ebebfd;
      }
    }

    h6 {
      font-weight: 400;
      font-size: 1rem;
      color: #040417;
    }
  }

  .submit-rect {
    display: flex !important;
    width: 100% !important;
    width: 100% !important;
    flex-direction: column !important;
    margin-top: 3rem !important;
    gap: 0.5rem !important;

    a {
      background: #5352ec;
      border-radius: 6.25rem;
      padding: 1rem 2rem;
      border: none;
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 1rem;
      color: #fafafe;
      font-weight: 500;
      align-items: center;
      gap: 0.625rem;
    }

    p {
      color: #999;
      font-size: 1rem;
      text-align: center;
    }
  }
}


@media only screen and (max-width: 480px) {
  .rect-title {
    margin-top: 3.5rem;
    padding: 4.75rem 4rem 8.75rem;

    h3 {
      font-size: 4rem;
      line-height: 4.217rem;
    }

    p {
      font-size: 1.2rem;
      line-height: 1.5rem;
      font-weight: 400;
    }
  }

  .rate {
    margin: 0;
    margin-top: -7rem;
  }

  .rate-bg {
    margin: 0 5%;
    gap: 1rem;
    padding: 2.25rem 1.25rem;

    .item-select-title {
      margin-bottom: 1rem;
    }
  }

  .detial-section {
    gap: 4rem;
    padding: 3rem 1.6rem 1.5rem;

    .detail-top {
      h3 {
        padding: 0 7%;
        font-size: 2rem;
        font-weight: 600;
        line-height: normal;
      }

      p {
        color: #040417;
        font-size: 1rem;
        margin-top: 1.2rem;
        padding: 0;
        text-align: center;
        line-height: 1.6rem;
      }
    }

    .list {
      padding: 0;
      gap: 2rem 1rem;

      .desc-bg {
        width: 45.4545454545%;
        height: 14rem;

        padding-top: 1rem;
        gap: 0;

        .desc {
          font-size: 1.2rem;
        }
      }
    }

    h3 {
      padding: 0 7%;
      font-size: 2rem;
      font-weight: 600;
      line-height: normal;
    }

    p {
      font-size: 1rem;
      padding: 0 3%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .rate {
    margin: 0, 10%;
  }

  .list {
    padding: 0;
    gap: 2rem 1rem;

    .desc-bg {
      width: 45%;
      height: 14rem;
    }
  }

  .hidden-scroll-bar::-webkit-scrollbar {
    display: none; /* 对于Webkit浏览器 */
  }
 
  .hidden-scroll-bar {
    scrollbar-width: none; /* 对于IE和Edge */
  }
}
</style>
