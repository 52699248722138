import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../pages/Home.vue'
import Rate from '../pages/Rate.vue'
import Rewards from '../pages/Rewards.vue'
import Download from '../pages/Download.vue'
import About from '../pages/About.vue'
import SellCard from '../pages/SellCard.vue'
import FAQS from '../pages/Faps.vue'
import Contact from '../pages/Contact.vue'
import AML from '../pages/AML.vue'
import Privacy_Policy from '../pages/Privacy_Policy.vue'
import Chat from '../components/Chat.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/rate',
    name: 'Rate',
    component: Rate
  },
  {
    path: '/rewards',
    name: 'Rewards',
    component: Rewards
  },
  {
    path: '/download',
    name: 'Download',
    component: Download
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/steam',
    name: 'Steam',
    component: SellCard
  },
  {
    path: '/google-play',
    name: 'Google',
    component: SellCard
  },
  {
    path: '/apple-itunes',
    name: 'Apple',
    component: SellCard
  },
  {
    path: '/amazon',
    name: 'Amazon',
    component: SellCard
  },
  {
    path: '/faqs',
    name: 'FAQS',
    component: FAQS
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/aml',
    name: 'AML',
    component: AML
  },
  {
    path: '/terms',
    name: 'Terms',
    component: AML
  },
  {
    path: '/policy',
    name: 'Policy',
    component: AML
  },
  {
    path: '/google66eac4597db7f7f5',
    name: 'google66eac4597db7f7f5',
    redirect: '/google66eac4597db7f7f5.html' 
  },
  {
    path: '/privacy_policy',
    name: 'Privacy_Policy',
    component: Privacy_Policy
  },
  {
    path: '/chat',
    name: 'Chat',
    component: Chat
  },
  {
    path: '/',
    redirect: '/home'
  }
]

const router = new VueRouter({
  mode: "history",
  routes
});
export default router

